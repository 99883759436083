import { useParams } from 'react-router-dom';
import { useGetAccount, useGetNetworkConfig, useGetSuccessfulTransactions } from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { Button } from 'antd';
import { useEffect } from 'react';
import { useDomainExist } from 'components/domainExist/DomainExistContext';
import { UseGetVoucherId } from 'domain/voucher/useGetVoucherId';
import { useGetVoucherNonces } from 'domain/voucher/usegetVoucherNonces';
import { getNft } from 'domain/nft/getNft';
import { notification } from 'antd';
import { contractAddress, resolverContractAddress } from 'config';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { getCorrectNonces } from 'domain/shared/getCorrectNonces';
import { PAYMENT_METHODS } from './components/PaymentSelect';
import { bech32ToHex, numberToHex, stringToHex } from 'domain/shared/formatTransformers';
import { RegisterEth } from './RegisterEth';
import { RegisterCustom } from './RegisterCustom';

export interface IRegister {
    isRegistrationAvailable: boolean;
    paymentMethod?: string;
    price?: number;
}

export const Register = ({ isRegistrationAvailable, paymentMethod, price }: IRegister) => {
    const { hasSuccessfulTransactions } = useGetSuccessfulTransactions();
    const { address } = useGetAccount();
    const { name } = useParams();
    const voucherId = UseGetVoucherId();
    const voucherNonce = useGetVoucherNonces();
    const { isDomainExistAction } = useDomainExist()!;
    const { network } = useGetNetworkConfig();
    const [method, token = ''] = paymentMethod?.split('::') || [];

    const VoucherRegister = async () => {
        try {
            const nft = await getNft({ voucherId, address, url: network.apiAddress });
            const correctNonce = getCorrectNonces(nft, voucherNonce, voucherId);

            if (!correctNonce.length) {
                notification.warning({
                    key: 'nonce-issue',
                    message: 'Registration failed. You do not have a registration voucher.',
                });
                return;
            }

            let tx = {
                data: `ESDTNFTTransfer@${stringToHex(voucherId)}@${numberToHex(correctNonce[0])}@01@${bech32ToHex(contractAddress)}@${stringToHex(
                    'registerDomain'
                )}@${stringToHex(name!)}@${bech32ToHex(resolverContractAddress)}`,
                receiver: address,
                gasLimit: '60000000',
                value: 0,
            };

            await sendTransactions({
                transactions: tx,
                transactionsDisplayInfo: {
                    processingMessage: 'Processing Voucher Register transaction',
                    errorMessage: 'An error has occurred during Voucher Register',
                    successMessage: 'Voucher Register transaction successful',
                },
                redirectAfterSign: false,
            });
        } catch (error) {
            notification.error({
                key: 'error-register',
                message: (error as any) || 'error during registration',
            });
        }
    };

    const EGLDRegister = async () => {
        try {
            let tx = {
                value: price,
                data: `registerDomain@${stringToHex(name!)}@${bech32ToHex(resolverContractAddress)}`,
                receiver: contractAddress,
                gasLimit: '60000000',
            };

            await refreshAccount();

            await sendTransactions({
                transactions: tx,
                transactionsDisplayInfo: {
                    processingMessage: 'Processing EGLD Register transaction',
                    errorMessage: 'An error has occurred during EGLD Register',
                    successMessage: 'EGLD Register transaction successful',
                },
                redirectAfterSign: false,
            });
        } catch (error) {
            notification.error({
                key: 'error-register',
                message: (error as any) || 'error during registration',
            });
        }
    };

    const ESDTRegister = async () => {
        try {
            let tx = {
                value: 0,
                data: `ESDTTransfer@${stringToHex(token)}@${numberToHex(price!)}@${stringToHex('registerDomain')}@${stringToHex(name!)}@${bech32ToHex(
                    resolverContractAddress
                )}`,
                receiver: contractAddress,
                gasLimit: '60000000',
            };

            await refreshAccount();

            await sendTransactions({
                transactions: tx,
                transactionsDisplayInfo: {
                    processingMessage: 'Processing ESDT Register transaction',
                    errorMessage: 'An error has occurred during ESDT Register',
                    successMessage: 'ESDT Register transaction successful',
                },
                redirectAfterSign: false,
            });
        } catch (error) {
            notification.error({
                key: 'error-register',
                message: (error as any) || 'error during registration',
            });
        }
    };

    const HypeRegister = async () => {
        try {
            let tx = {
                value: 0,
                data: `registerHypeDomain@${stringToHex(name!)}@${bech32ToHex(resolverContractAddress)}`,
                receiver: contractAddress,
                gasLimit: '60000000',
            };

            await refreshAccount();

            await sendTransactions({
                transactions: tx,
                transactionsDisplayInfo: {
                    processingMessage: 'Processing HYPE Register transaction',
                    errorMessage: 'An error has occurred during HYPE Register',
                    successMessage: 'HYPE Register transaction successful',
                },
                redirectAfterSign: false,
            });
        } catch (error) {
            notification.error({
                key: 'error-register',
                message: (error as any) || 'error during registration',
            });
        }
    };

    useEffect(() => {
        if (hasSuccessfulTransactions) {
            (async () => await isDomainExistAction(name))();
        }
    }, [hasSuccessfulTransactions]);

    const register = {
        [PAYMENT_METHODS.EGLD]: EGLDRegister,
        [PAYMENT_METHODS.RARE]: ESDTRegister,
        [PAYMENT_METHODS.VOUCHER]: VoucherRegister,
        [PAYMENT_METHODS.HYPE]: HypeRegister,
    };

    const isDrillDomain = name?.endsWith('.drill');
    const isMaticRegular = method === PAYMENT_METHODS.MATIC_REGULAR;
    const isMaticCustom = method === PAYMENT_METHODS.MATIC_CUSTOM;
    const isMaticfree = method === PAYMENT_METHODS.MATIC_NFT;

    // If it's a ".drill" domain and the payment method is MATIC_REGULAR, render RegisterCustom component
    if (isDrillDomain && isMaticCustom) {
        return <RegisterCustom isRegistrationAvailable={isRegistrationAvailable} price={price} />;
    } 
    // If it's not a ".drill" domain but the payment method is MATIC_REGULAR, render RegisterEth component
    else if (isMaticRegular) {
        return <RegisterEth isRegistrationAvailable={isRegistrationAvailable} price={price} />;
    } 
    else if (isMaticfree) {
        return <RegisterEth isRegistrationAvailable={isRegistrationAvailable} price={0} />;
    } 
   
    
    return (
        <Button onClick={register[method]} disabled={!isRegistrationAvailable} style={{ marginLeft: '8px' }}>
            Register
        </Button>
    );
};