import { createSmartContract } from './smartContract';
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { BytesValue, ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';

const resultsParser = new ResultsParser();

export const useIsDomainInReservedList = () => {
    const { network } = useGetNetworkConfig();
    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const contract = createSmartContract({});

    const isDomaInReserverList = async ( record: string ) => {
        try {
            const query = contract.createQuery({
                func: new ContractFunction('isDomainInReservedList'),
                args: [ BytesValue.fromUTF8(record)]
            })
            const queryResponse = await proxy.queryContract(query);

            const endpointDefinition = contract.getEndpoint('isDomainInReservedList');
            const { firstValue } = resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );

            const isInReservedList = Boolean(firstValue?.valueOf());
            return {domain:record, isInReservedList};
        } catch (error:any) {
            console.error('Unable to call isDomainInReservedList', error)
        }
    }

    return isDomaInReserverList;

}