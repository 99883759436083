import { notification } from 'antd';
import { Loading } from 'components/Loading';
import { PriceBlock } from 'components/PriceBlock/PriceBlock';
import { useEffect, useMemo, useState } from 'react';
import { useBalance } from 'wagmi';
import { useGetCustomPrice } from 'domain-eth/useGetCustomPrice';

export const CustomPrice = ({
    setIsRegistrationAvailable,
    setItemPrice,
    address,
    names,
}: {
    setIsRegistrationAvailable: Function;
    setItemPrice: Function;
    address?: `0x${string}`;
    names: string[];
}) => {
    const getEthPrice = useGetCustomPrice();
    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState<any>();
    const [isEnoughMoney, setIsEnoughMoney] = useState(false);

    const etherBalance = useBalance({
        address,
        onError() {
            notification.error({
                key: 'eth error',
                message: 'Cannot get matic balance',
            });
        },
    });

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const price = await getEthPrice(names);
                if (typeof price === 'number') {
                    setPrice(price);
                    setItemPrice(price);
                }
            } catch (error) {
                notification.error({
                    key: 'eth error',
                    message: 'Cannot get price details',
                });
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (typeof price === 'number' && etherBalance.isSuccess) {
            setIsEnoughMoney(parseFloat(etherBalance.data?.formatted || '') >= price);
        }
    }, [price, etherBalance]);

    useEffect(() => {
        setIsRegistrationAvailable(isEnoughMoney);
    }, [isEnoughMoney]);

    const maticPriceDisplay = useMemo(() => {
        if (price) {
            let priceStr = price.toFixed(20);
            if (priceStr.match(/\./)) {
                priceStr = priceStr.replace(/\.?0+$/, '');
            }

            return priceStr;
        }
    }, [price]);

    return loading || etherBalance.isLoading ? (
        <Loading />
    ) : (
        <PriceBlock price={maticPriceDisplay} showWarning={!isEnoughMoney} uom="MATIC" multipleDomains />
    );
};
