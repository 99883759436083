import { LinkOutlined } from '@ant-design/icons';
import { ExplorerLink as ExplorerLinkMvx } from '@multiversx/sdk-dapp/UI';
import { Platform } from 'providers/PlatformContext/Platforms';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { polygon } from 'wagmi/chains';

const ExplorerLinkText = () => (
    <>
        Explorer <LinkOutlined />
    </>
);

export const ExplorerLink = ({ address }: { address: string }) => {
    const [platform] = usePlatform();

    if (platform === Platform.MVX) {
        return <ExplorerLinkMvx page={`address/${address}`} text={ExplorerLinkText()}></ExplorerLinkMvx>;
    }

    return (
        <a href={`${polygon.blockExplorers.default.url}/address/${address}`}>
            <ExplorerLinkText />
        </a>
    );
};
