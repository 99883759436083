import { ContractFunction, ResultsParser } from "@multiversx/sdk-core/out";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { createSmartContract } from "domain/smartContract";


const resultsParser = new ResultsParser();

export const useGetESDTPayments = () => {
    const { network } = useGetNetworkConfig();
    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const contract = createSmartContract({});

    const getESDTPayments = async () => {
        try {
            const query = contract.createQuery({
                func: new ContractFunction('getEsdtPayments'),
            })
            const queryResponse = await proxy.queryContract(query);
            const endpointDefinition = contract.getEndpoint('getEsdtPayments');
            const {firstValue}= resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );

            let esdtMethods :string[]=firstValue?.valueOf()|| [];

            return esdtMethods;
        } catch (error) {
            console.error('Unable to call getEsdtPayments', error);
        }
        
    }

    return getESDTPayments;
}