import React, {useState, useEffect} from 'react';
import '../../App.css';
import { Layout, ConfigProvider, message, Button, Input} from 'antd';
import background from "./../../assets/background.gif";
import { Radio, Row, Card, Typography, Select, Col} from 'antd/lib';
import {useLocation, useNavigate} from 'react-router-dom'
import Navbar from 'components/Navbar';
import { Connect} from 'components/Connect/Connect';
import { NetworkType } from 'components/NetworkType';
import logo from '../../assets/xds_pic.png';
import { useGetIsLoggedIn, useGetDomainOwner} from 'hooks';
import { Platform } from 'providers/PlatformContext/Platforms';
import { useAccount } from 'wagmi';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { initializeApp } from "firebase/app";
import 'firebase/database';
import { collection, addDoc, getDocs, getDoc, updateDoc,getFirestore, query, where, setDoc,doc} from "firebase/firestore"; 
import { v4 as uuidv4 } from 'uuid';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { CopyOutlined } from '@ant-design/icons';
import { Space } from 'antd';
interface LeaderboardEntry {
  userId: string;
  userName: string;
  rank: number;
  referralCount: number;
}
interface UserData {
  address: string;

}
interface UserWithRank {
  userId: string;
  username: string;
  referralCount: number;
  rank: number; // Add rank property
}
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);


const Referral: React.FC =({existsOnPlatform }: { existsOnPlatform?: Platform })=> {
  const isLoggedIn = useGetIsLoggedIn();
  const getDomainOwner = useGetDomainOwner(existsOnPlatform);
  const [selectedValue, setSelectedValue] = useState('profile'); 
  const [username, setUsername] = useState('');
  const [userName, setUserName] = useState<string[]>([]);
  const Pathname= useLocation().pathname;
  const Navigate = useNavigate();
  const { Content } = Layout;
  const { Title } = Typography;
  const {Option} = Select;
  const [domainEnding, setDomainEnding] = useState('.pol'); 
  const domainUsername = `${username}${domainEnding}`;
  const { address: ethAddress } = useAccount();
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [submitSuccess, setSubmitSuccess]= useState(false);
  const {address} = useGetAccountInfo();
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [platformName, usePlatformName]= usePlatform();
  const [referralFieldsCount, setReferralFieldsCount] = useState(0);
  const [userId, setUserId] = useState<string | null>(null);
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardEntry[]>([]);
  const [useraddress, setAddress] = useState(''); 

  useEffect(() => {
    const fetchUsernames = async () => {
      try {
        // Create a query to fetch usernames where the 'address' field matches ethAddress or address
        const usersCollection = collection(db, 'users');
        const q = query(usersCollection, where('address', 'in', [ethAddress || address]));

        // Fetch usernames from Firestore
        const usersSnapshot = await getDocs(q);
        const usernamesData = usersSnapshot.docs.map(doc => doc.data().username);
        setUserName(usernamesData);
      } catch (error) {
        console.error('Error fetching usernames:', error);
      }
    };

    fetchUsernames();
  }, [ethAddress, address]);


  useEffect(() => {
    const storedButtonsVisible = sessionStorage.getItem('buttonsVisible');
    if (storedButtonsVisible !== null) {
      setButtonsVisible(storedButtonsVisible === 'false');
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('buttonsVisible', String(buttonsVisible)); // Store the buttonsVisible state in session storage
  }, [buttonsVisible]);

  const handleVerify = async (name:string) => {
    if (username.trim() !== '') {
      
      try {
    
        const ownerName = await getDomainOwner(name!)
        console.log(ownerName);
        
    
        if ( ownerName === ethAddress || ownerName === address ) {
          message.success('Verification successful!');
          setVerificationSuccess(true); 
        } else {
          message.error('Verification unsuccessful!');
          console.log('Verification failed: Entered username does not match domain owner\'s name');
        }
      } catch (error) {
        console.error('Error fetching domain owner name:', error);
      }
    } else {
      console.log('Please enter a username');
    }
  };
    const handleChange = (e: any) => {
      const value = e.target.value;
      setSelectedValue(value);
    };
    const selectAfter = (
      <Select defaultValue={domainEnding} onChange={(value) => setDomainEnding(value)}>
        <Option value=".pol">.pol</Option>
        <Option value=".rat">.rat</Option>
        <Option value=".drill">.drill</Option>
        <Option value=".egld">.egld</Option>
        <Option value=".mvx">.mvx</Option>
        <Option value=".erd">.erd</Option>
        <Option value=".mex">.mex</Option>
        <Option value=".hype">.hype</Option>
        <Option value=".bear">.bear</Option>
        <Option value=".ape">.ape</Option>
      </Select>
    );

    const handleSubmit = async () => {
      if (verificationSuccess) {
        try {
          const userQuerySnapshot = await getDocs(query(collection(db, 'users'), where('address', '==', ethAddress || address)));

          if (!userQuerySnapshot.empty) {
            message.error('User already exists with this domain name, try another one');
            return;
          }
          const randomId = uuidv4();
          const docRef = await addDoc(collection(db, "users"), {
            id: randomId,
            username: domainUsername,
            address: ethAddress || address,
            referralCode: domainUsername,
            referrals:[]
          });
          await setDoc(doc(db, `users/${docRef.id}/referral/referral`), {});
          console.log("Document written with ID: ", docRef.id);
          message.success('Submission Successful ');
          setSubmitSuccess(true);
          setButtonsVisible(false);
          sessionStorage.setItem('buttonsVisible', 'false'); 
        } catch (error) {
          console.error('Error adding data :', error);
        }
      } else {
        console.log('Please verify your username first');
      }
    };

    const handleUpdate = async (name:string) => {
      try {
        // Verify the domain username's presence
    const ownerName = await getDomainOwner(name!);

    if (ownerName !== ethAddress && ownerName !== address) {
      message.error('Domain name not found');
      return;
    }
        const querySnapshot = await getDocs(query(collection(db, "users"), where("username", "==", domainUsername)));
        if (!querySnapshot.empty) {
          message.error('Username already exists. Please choose a different one.');
        } else {
          const querySnapshotAll = await getDocs(collection(db, "users"));
          querySnapshotAll.forEach(async (doc) => {
            if (doc.data().address === (ethAddress || address)) {
              try {
                // Update the document with the new username
                await updateDoc(doc.ref, { username: domainUsername });
                message.success('Username updated successfully');
                setButtonsVisible(true);
              } catch (error) {
                console.error('Error updating username:', error);
              }
            }
          });
        }
      } catch (error) {
        console.error('Error querying Firestore:', error);
      }
    };

    const copyReferralLink = () => {
     
      const referralLink = `https://app.xdomain.services/?referral=${userName}`;
    
      navigator.clipboard.writeText(referralLink).then(() => {
        message.success('Referral link copied to clipboard');
      }).catch((error) => {
        console.error('Error copying referral link:', error);
        message.error('Failed to copy referral link');
      });
    };
    const getUserIds = async () => {
      try {
          const usersQuerySnapshot = await getDocs(collection(db, 'users'));
          const userIds: string[] = [];
          console.log(userIds);
          
          // Iterate over each document in the query snapshot
          usersQuerySnapshot.forEach((userDoc) => {
              // Get the ID of each user document and add it to the userIds array
              userIds.push(userDoc.id);
          });
  
          return userIds;
      } catch (error) {
          console.error('Error fetching user documents:', error);
          return [];
      }
  };


  useEffect(() => {
    const fetchUserId = async () => {
        try {
            // Query Firestore to find users with the matching address
            const userQuerySnapshot = await getDocs(query(collection(db, 'users'), where('address', '==', ethAddress || address)));

            // Iterate over each document in the query snapshot
            userQuerySnapshot.forEach((userDoc) => {
                const userData = userDoc.data();
                if (userData.address === (ethAddress || address)) {
                    // If the address matches, set the user ID and stop iterating
                    const userId = userDoc.id;
                    setUserId(userId);
                }
            });

            // If no matching user document is found, log a message
            if (userId === '') {
                console.log('User with the specified address not found');
            }
        } catch (error) {
            console.error('Error fetching user ID:', error);
        }
    };

    fetchUserId(); // Call the function to fetch user ID
}, [ethAddress, address]); // Add ethAddress and address as dependencies


  // useEffect hook to fetch referral fields count when userId changes
  useEffect(() => {
    const fetchReferralFieldsCount = async () => {
        try {
            if (userId) {
                // Fetch the user document using the userId
                const userDocSnapshot = await getDoc(doc(db, 'users', userId));

                // Check if the user document exists
                if (userDocSnapshot.exists()) {
                    // Get the user data
                    const userData = userDocSnapshot.data();

                    // Extract the referrals array from the user data
                    const referrals = userData ? userData.referrals : [];

                    // Count the number of elements in the referrals array
                    const count = referrals.length;
                    setReferralFieldsCount(count);
                } else {
                    console.log('User document does not exist for the current user');
                }
            }
        } catch (error) {
            console.error('Error fetching user document:', error);
            // Handle errors gracefully
        }
    };

    fetchReferralFieldsCount(); // Call the function to fetch referral fields count
}, [userId, ethAddress, address]); // Add ethAddress and address as dependencies


const updateLeaderboard = async () => {
  try {
      // Fetch all user documents
      const usersQuerySnapshot = await getDocs(collection(db, 'users'));

      // Array to store user objects with their referral counts
      const usersWithReferralCounts: UserWithRank[] = []; // Define type explicitly

      // Iterate over each user document
      for (const userDoc of usersQuerySnapshot.docs) {
          const userId = userDoc.id;
          const userData = userDoc.data(); // Get user data
          const username = userData.username;

          // Get the referrals array from the user data or create an empty array if it doesn't exist
          const referrals = userData.referrals || [];

          // Count the number of referrals
          const referralCount = referrals.length;

          usersWithReferralCounts.push({ userId, username, referralCount, rank: 0 }); // Initialize rank as 0
      }

      // Sort users by their referral counts in descending order
      usersWithReferralCounts.sort((a, b) => b.referralCount - a.referralCount);

      // Assign ranks based on sorted order
      let currentRank = 1;
      let prevReferralCount = Infinity;
      usersWithReferralCounts.forEach((user, index) => {
          if (user.referralCount < prevReferralCount) {
              currentRank = index + 1; // Update rank when referral count changes
          }
          user.rank = currentRank; // Assign rank to user
          prevReferralCount = user.referralCount; // Update previous referral count
      });

      // Update the leaderboard collection with the sorted users
      for (const user of usersWithReferralCounts) {
          const leaderboardDocRef = doc(db, 'leaderboard', user.userId);
          await setDoc(leaderboardDocRef, { rank: user.rank, username: user.username, referralCount: user.referralCount });
      }

      console.log('Leaderboard updated successfully');
  } catch (error) {
      console.error('Error updating leaderboard:', error);
  }
};

    
    
    useEffect(() => {
      updateLeaderboard(); // Call once when component mounts
      const intervalId = setInterval(updateLeaderboard, 3600000); // Update every hour
  
      return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);

    useEffect(() => {
      const fetchLeaderboardData = async () => {
        try {
          // Fetch leaderboard data from your database
          const leaderboardSnapshot = await getDocs(collection(db, 'leaderboard'));
  
          // Map the fetched data to LeaderboardEntry objects
          const data = leaderboardSnapshot.docs.map(doc => ({
            userId: doc.data().userId,
            userName: doc.data().username,
            rank: doc.data().rank,
            referralCount: doc.data().referralCount
          })) as LeaderboardEntry[];
  
          // Set the leaderboard data to the state
          setLeaderboardData(data);
        } catch (error) {
          console.error('Error fetching leaderboard data:', error);
        }
      };
  
      // Call the fetchLeaderboardData function when the component mounts
      fetchLeaderboardData();
    }, []); 
    
  
  return (
    <Layout className='home-layout'  style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', color:'white', marginTop:'10px' }}>
      <Navbar>
      <img className="logo-img" src={logo} alt="logo_img"  style={{ width: '50px', height: '50px' }}/>
                    <Connect showSelect contentClassName="connect-transparent">
                        <ConfigProvider
                        >
                            <div className="connect-typography">
                                {isLoggedIn ? <NetworkType /> :null}
                                
                            </div>
                        </ConfigProvider>
                    </Connect>
      </Navbar>
      <Content style={{ display:'flex',justifyContent:'center', paddingTop:'2rem'}}>
    <Title level={2} style={{ marginTop: '10px', color: 'white', wordWrap:"break-word", marginLeft:'85px', marginRight:'85px' }}>Refer Friends To Earn Rewards</Title>
    </Content>
        <Row >
      <Content style={{ display: 'flex', justifyContent:'center', paddingTop:'2rem'}}>
    <Radio.Group defaultValue= "profile" onChange={handleChange} style={{ border: '1px solid white', borderRadius:'15px', backgroundColor:"unset !important" }} >
      <Radio.Button value='profile' type='primary' style={{ margin:'8px', textAlign:'center', borderRadius:'15px', fontWeight:'bold', color:"white", backgroundColor:"#9b60ff"}}  >YourProfile</Radio.Button>
      <Radio.Button value='leaderboard' type='primary' style={{  margin:'8px', textAlign:'center', borderRadius:'15px', fontWeight:'bold', color:"white", backgroundColor:'#9b60ff'}} >Leaderboard</Radio.Button>
    </Radio.Group>
    </Content>
    </Row>
  
    <Content style={{ display: 'flex', justifyContent:'center', paddingTop:'2rem'}}>
    {isLoggedIn ? (
    <Card title={selectedValue === 'profile' ? 'Profile' : 'Leaderboard'} style={{ width:'500px',marginBottom:'10px', marginLeft:'10px', marginRight:'10px', backgroundImage:'radial-gradient(circle at center, #321564 0%, #321564 50%, black 100%)'}} headStyle={{color:'white'}}>
    {selectedValue === 'profile' && (
  <><section>
    <p className='username'>Set your xdomain username </p>
    <p className='name'>Username: [ {userName} ]</p>
    <p className='heading'>Enter your Xdomain name below for referral link </p>
    <Input    addonAfter= {selectAfter}
      defaultValue="mysite"
      onChange={(e) => {
        const value = e.target.value;
        if (/^[a-zA-Z0-9_]*$/.test(value)) {
          setUsername(value)
        }}}
        value={username}
        style={{background:'white'}} // Set opacity based on verificationSuccess
      />
    
      <Row justify={'center'} className='button-flex'>
      <Button type="primary" className='verify-button' onClick={() => handleVerify(domainUsername)} disabled={!buttonsVisible || verificationSuccess}>Verify</Button>
      <Button type="primary" className='verify-setup' onClick={handleSubmit} disabled={!buttonsVisible || submitSuccess}>Submit</Button>
      <Button type="primary" className='update-setup' onClick={() => handleUpdate(domainUsername)}  >Update</Button>
      
    </Row>
    </section>
    <section className='referral-section'>
      <p className='referral-heading'>Referral Link to Share</p>
      <p className='heading'>Copy the link & share it with friends, Invite your friend and earn upto 10% on .rat,.drill and 30% on .pol on Domain name minting fee.</p>
      {/* Assuming `referralCode` is the code generated for the referral */}
      <Space.Compact  style={{ width: '100%' }}>
      <Input value={`https://app.xdomain.services/?referral=${userName}` } readOnly />
      <CopyOutlined className='copyicon' onClick={() => copyReferralLink()} />
      </Space.Compact>
      {/* Button to copy the link */}
      
    </section>

    <section className='referralCount'>
    <p className='referral-heading'>Reward To claim</p>
    <p className='heading'> Reward and cashback the amount will be updated after buyer purchase the domain, Reward claim button will be enabled on every Sunday  </p>
    <Row className='button-flex'>
    <div>
      <p className='referralHeading'>Total Referrals:</p>
      <p className='referralHeading' style={{ border: '2px solid white', borderRadius: '15px', padding: '5px', fontWeight: 'bold', fontSize: '30px', width: "135px", justifyContent: 'center', textAlign: 'center' }}>{referralFieldsCount}</p>
    </div>

    <div>
      <p className='referralReward'>Total Reward:</p>
      <p className='referralReward' style={{ border: '2px solid white', borderRadius: '15px', padding: '5px', fontWeight: 'bold', fontSize: '30px', width: "135px", justifyContent: 'center', textAlign: 'center' }}>0$</p>
    </div>


    <div>
      <p className='referralReward'>Total Cashback:</p>
      <p className='referralReward' style={{ border: '2px solid white', borderRadius: '15px', padding: '5px', fontWeight: 'bold', fontSize: '30px', width: "135px", justifyContent: 'center', textAlign: 'center' }}>0$</p>
    </div>
    </Row>
      </section>


      <section>
        <Row justify={'center'}>
        <Button type="primary" className='update-setup'>Claim Reward</Button>
        </Row>
      </section>
     
  
</>
    )}
{selectedValue === 'leaderboard' && (
 <table>

 <thead>
   <tr className='userName'>
    <th style={{paddingRight:'10px'}}>UserName</th>
    <th style={{paddingRight:'10px'}}>Rank</th>
     <th style={{paddingRight:'10px'}}>Referral Count</th>
   </tr>
 </thead>
 <tbody>
   {leaderboardData.map((entry: LeaderboardEntry, index: number) => (
     <tr key={entry.userId} className='Data'>
      <td >{entry.userName}</td>
       <td>{entry.rank}</td>
       <td>{entry.referralCount}</td>
     </tr>
   
   ))}
 </tbody>
</table>
)}
    </Card>
     ) : (
      <div>Please Login First.</div>
    )}
    </Content>
        </Layout>
  );
}

export default Referral;