import { Typography } from 'antd';
import { NETWORK_ID } from 'config';

const NETWORK_TYPE: any = {
    T: 'Testnet Network',
    D: 'Devnet Network',
    1: 'Main Network',
};

export const NetworkType = () => {
    let network = NETWORK_TYPE[NETWORK_ID];

    // If the network is "Main Network", return null to not render anything
    if (network === 'Main Network') {
        return null;
    }

    return <Typography.Title level={4}>{network}</Typography.Title>;
};
