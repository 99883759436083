import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';
import { useEffect, useState } from 'react';
import { createSmartContract } from 'domain/smartContract';


const resultsParser = new ResultsParser();

export const UseGetNftTokenId = () => {
    const { network } = useGetNetworkConfig();
    const [ nftTokenId, setNftTokenId ] = useState('');

    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const contract = createSmartContract({});

    const getNftTokenId = async () => {
        try {
            const query = contract.createQuery({
                func: new ContractFunction('getNftTokenId')
            });
            const queryResponse = await proxy.queryContract(query);
            const endpointDefinition = contract.getEndpoint('getNftTokenId');

            const {firstValue} = resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );
            setNftTokenId(firstValue?.valueOf())

        } catch (error) {
            console.error('Unable to call getNftTokenId', error);
        }
    }

    useEffect(() => {
        getNftTokenId();
    }, []);
    
    return nftTokenId;
}