import { Button } from 'antd';
import { IRegister } from './Register';
import { useCallback, useEffect } from 'react';
import { notification } from 'antd';

import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import registryAbi from 'domain-eth/xDomainRegistry.abi.json';
import { XDomainRegistry, AddressResolver } from 'config';
import { parseEther } from '@ethersproject/units';
import { useDomainExist } from 'components/domainExist/DomainExistContext';
import { useParams } from 'react-router-dom';

export const RegisterCustom = ({ isRegistrationAvailable, price }: IRegister) => {
    const { isDomainExistAction } = useDomainExist()!;
    const { name } = useParams();
    const selectedDomains = name?.split(',');
    const { config } = usePrepareContractWrite({
        address: XDomainRegistry,
        abi: registryAbi,
        functionName: 'registerDomain',
        args: [selectedDomains, AddressResolver],
        value: parseEther((price!).toFixed(20)).toBigInt(),
    });
    const { data, write } = useContractWrite(config);

    const { status } = useWaitForTransaction({
        hash: data?.hash,
        onSuccess: () => {
            isDomainExistAction();
        },
    });

    useEffect(() => {
        const notifications = {
            loading: 'Processing MATIC Register transaction',
            error: 'An error has occurred during MATIC Register',
            success: 'MATIC Register transaction successful',
        };

        if (status === 'loading') {
            notification.info({ message: notifications.loading });
        }
        if (status === 'error') {
            notification.error({ message: notifications.error });
        }
        if (status === 'success') {
            notification.success({ message: notifications.success });
        }
    }, [status]);

    const register = useCallback(() => {
        try {
            write?.();
        } catch (error) {
            notification.error({
                key: 'error-register',
                message: (error as any) || 'error during registration',
            });
        }
    }, [write]);

    return (
        <Button onClick={register} disabled={!isRegistrationAvailable || !write} style={{ marginLeft: '8px' }}>
            Register
        </Button>
    );
};
