import { readContract } from '@wagmi/core';
import { domainStorageAddressEt, DomainStorage } from 'config';
import domainStorageAbi from './DomainStorage.abi.json';

export const useIsDomainExist = () => {
    const isDomainExist = async (record: string) => {
        try {
            let isExist;
            if (record.endsWith('.drill')) {
                // If it's a .drill domain, query the drillAddress
                isExist = await readContract({
                    address: DomainStorage,
                    abi: domainStorageAbi,
                    functionName: 'isDomainExist',
                    args: [record],
                });
            } else {
                // Otherwise, query the domainStorageAddressEt
                isExist = await readContract({
                    address: domainStorageAddressEt,
                    abi: domainStorageAbi,
                    functionName: 'isDomainExist',
                    args: [record],
                });
            }

            return { domain: record, isExist };
        } catch (error: any) {
            console.error('Unable to call isDomainExist', error);
        }
    };

    return isDomainExist;
};
