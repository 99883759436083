import { readContract } from '@wagmi/core';
import { domainStorageAddressEt, DomainStorage} from 'config';
import domainStorageAbi from './DomainStorage.abi.json';

export const useIsDomainInReservedList = () => {
    const isDomainInReservedList = async (record: string) => {
        try {
            let isInReservedList;
            if (record.endsWith('.drill')) {
                // If it's a .drill domain, query the drillAddress
                isInReservedList = await readContract({
                    address: DomainStorage,
                    abi: domainStorageAbi,
                    functionName: 'isDomainInReservedList',
                    args: [record],
                });
            } else {
                // Otherwise, query the domainStorageAddressEt
                isInReservedList = await readContract({
                    address: domainStorageAddressEt,
                    abi: domainStorageAbi,
                    functionName: 'isDomainInReservedList',
                    args: [record],
                });
            }

            return {
                domain: record,
                isInReservedList,
            };
        } catch (error: any) {
            console.error('Unable to call isDomainInReservedList', error);
        }
    };

    return isDomainInReservedList;
};
