import { createSmartContract } from 'domain/smartContract';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';
import { useEffect, useState } from 'react';



const resultsParser = new ResultsParser();

export const UseGetVoucherId = () => {
    const { network } = useGetNetworkConfig();
    const [ voucherId, setVoucherId ] = useState('');

    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const contract = createSmartContract({});

    const getVoucherId = async () => {
        try {
            const query = contract.createQuery({
                func: new ContractFunction('getVoucherId')
            });
            const queryResponse = await proxy.queryContract(query);
            const endpointDefinition = contract.getEndpoint('getVoucherId');

            const {firstValue} = resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );
            setVoucherId(firstValue?.valueOf())

        } catch (error) {
            console.error('Unable to call getVoucherId', error);
        }
    }

    useEffect(() => {
        getVoucherId();
    }, []);
    
    return voucherId;
}