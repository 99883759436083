import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { Card, Collapse, Descriptions, Divider, Space, Typography } from 'antd';
import { Col, Row } from 'antd';
import { BlockIcon } from 'components/icons';
import { Loading } from 'components/Loading';
import { getNFTMedia } from 'domain/nft/getNFTMedia';
import { UseGetNFTNonce } from 'domain/nft/getNFTNonce';
import { UseGetNftTokenId } from 'domain/nft/getNftTokenId';
import { useGetDomainOwner, useGetResolver, useGetResolverData } from 'hooks';
import { useEffect, useState } from 'react';
import './DomainDetails.css';
import { ExplorerLink } from 'components/ExplorerLink';
import { useGetNftMedia } from 'domain-eth/useGetNftMedia';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { Platform } from 'providers/PlatformContext/Platforms';
import { useDomainExist } from 'components/domainExist/DomainExistContext';

const SingleDomainDetails = ({ name, isDomainExist, existsOnPlatform }: { name: string; isDomainExist: boolean; existsOnPlatform?: Platform }) => {
    const getResolverData = useGetResolverData(existsOnPlatform);
    const getResolver = useGetResolver(existsOnPlatform);
    const getDomainOwner = useGetDomainOwner(existsOnPlatform);
    const nftTokenId = UseGetNftTokenId();
    const getNFTNonce = UseGetNFTNonce();
    const { network } = useGetNetworkConfig();
    const [platform] = usePlatform();

    const activePlatform = existsOnPlatform || platform;

    const getNftMediaEth = useGetNftMedia();

    const [resolverData, setResolverData] = useState<string>('');
    const [owner, setOwner] = useState<string>('');
    const [resolver, setResolver] = useState('');
    const [nftMediaUrl, setNftMediaUrl] = useState<{ original: string; url: string }>();
    const [nftNonce, setNftNonce] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const [nftDetailLoading, setNftDetailLoading] = useState(false);
    const rootDomain = (name || '').split('.').pop() || '';

    useEffect(() => {
        if (isDomainExist) {
            (async () => {
                setLoading(true);
                const [resolverDomainData, resolverDomain, ownerDomain, nftNonceData] = await Promise.all([
                    getResolverData(name!),
                    getResolver(name!),
                    getDomainOwner(name!),
                    getNFTNonce(name!),
                ]);

                setResolverData(resolverDomainData!);
                setResolver(resolverDomain!);
                setOwner(ownerDomain!);
                setNftNonce(nftNonceData!);
                setLoading(false);
            })();
        }
    }, [isDomainExist]);

    useEffect(() => {
        if (!isDomainExist) return;
        (async () => {
            setNftDetailLoading(true);
            if (activePlatform === Platform.MVX && nftTokenId && nftNonce) {
                const nftDetails = await getNFTMedia({ nftTokenId, nftNonce, url: network.apiAddress });
                setNftMediaUrl({ original: nftDetails.media[0].originalUrl, url: nftDetails.url });
            } else if (activePlatform === Platform.MATIC) {
                const nftDetails = await getNftMediaEth(rootDomain);
                setNftMediaUrl({ original: nftDetails as any, url: nftDetails as any });
            }
            setNftDetailLoading(false);
        })();
    }, [nftTokenId, nftNonce, isDomainExist]);

    return loading ? (
        <Loading />
    ) : (
        <Row>
            <Col xs={24}>
                <Descriptions column={1}>
                    <Descriptions.Item label={<Typography.Title level={5}>NAME</Typography.Title>}>{name}</Descriptions.Item>
                    <Descriptions.Item label={<Typography.Title level={5}>PARENT</Typography.Title>}>{rootDomain}</Descriptions.Item>
                    <Descriptions.Item label={<Typography.Title level={5}>OWNER</Typography.Title>}>
                        <Row justify="space-between" style={{ width: '100%' }}>
                            <Space>
                                <BlockIcon />
                                <Typography.Text copyable>{owner || 'Not owned'}</Typography.Text>
                            </Space>
                            {owner && <ExplorerLink address={owner} />}
                        </Row>
                    </Descriptions.Item>
                </Descriptions>
            </Col>
            {isDomainExist && (
                <Col xs={24}>
                    <Card title="Records" className="detail-card">
                        <Descriptions column={1}>
                            <Descriptions.Item label={<Typography.Title level={5}>ADDRESS</Typography.Title>}>
                                <Row justify="space-between" style={{ width: '100%' }}>
                                    <Space>
                                        <BlockIcon />
                                        <Typography.Text copyable>{resolverData || 'No address found'}</Typography.Text>
                                    </Space>
                                    {resolverData && <ExplorerLink address={resolverData} />}
                                </Row>
                            </Descriptions.Item>
                            {nftMediaUrl && nftDetailLoading ? (
                                <Loading />
                            ) : (
                                <Descriptions.Item label={<Typography.Title level={5}>MEDIA</Typography.Title>}>
                                    <Row style={{ width: '100%' }}>
                                        <Col span={24}>
                                            <Row justify="space-between">
                                                <Typography.Text copyable>
                                                    {/* <ExplorerLink
                                                        page={`${nftMediaUrl?.original}`}
                                                        text={<>{nftMediaUrl?.original}</>}
                                                    ></ExplorerLink> */}
                                                    {nftMediaUrl?.url}
                                                </Typography.Text>
                                            </Row>
                                        </Col>
                                        <Col span={24}>
                                            <img
                                                src={nftMediaUrl?.url}
                                                alt="nftMediaUrl"
                                                style={{ width: '180px', marginTop: '8px', height: '100%' }}
                                            />
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                            )}
                        </Descriptions>
                    </Card>
                </Col>
            )}
            {/* <RecordSection /> */}
            <Col xs={24}>
                <Collapse ghost>
                    <Collapse.Panel key={1} header={<Typography.Title level={5}>ADVANCED SETTINGS</Typography.Title>}>
                        <Descriptions column={1}>
                            <Descriptions.Item label={<Typography.Title level={5}>RESOLVER</Typography.Title>}>
                                <Row justify="space-between" style={{ width: '100%' }}>
                                    <Space>
                                        <BlockIcon />
                                        <Typography.Text copyable>{resolver || 'No address found'}</Typography.Text>
                                    </Space>
                                    {resolver && <ExplorerLink address={resolver} />}
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Collapse.Panel>
                </Collapse>
            </Col>
        </Row>
    );
};

export const DomainDetails = () => {
    const { domain } = useDomainExist();

    return domain.map(({ domain, isExist, existsOnPlatform }) => (
        <Row key={domain}>
            <SingleDomainDetails name={domain} isDomainExist={isExist} existsOnPlatform={existsOnPlatform} />
            <Divider />
        </Row>
    ));
};
