import { useIsDomainExist } from 'hooks';
import { Platform } from 'providers/PlatformContext/Platforms';
import { useCart } from 'providers/cart/useCart';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

export interface IDomain {
    domain: string;
    isExist: boolean;
    existsOnPlatform?: Platform;
}

interface IDomainExistContext {
    isDomainExistAction: Function;
    domain: IDomain[];
    loading: boolean;
}

const DomainExistContext = createContext<IDomainExistContext>({
    domain: [],
    loading: false,
    isDomainExistAction: () => {},
});

export const DomainExistProvider = () => {
    const { name } = useParams();
    const { setItems, setReadonly } = useCart();
    const checkIsDomainExist = useIsDomainExist();
    const [domain, setDomain] = useState<IDomain[]>([]);
    const [loading, setLoading] = useState(true);

    const domainNames = name?.split(',');

    const isDomainExistAction = useCallback(async () => {
        if (domainNames?.length) {
            setLoading(true);
            const result = await checkIsDomainExist(domainNames);
            setDomain(result);
            setLoading(false);
        }
    }, [name]);

    useEffect(() => {
        setReadonly(true);

        return () => {
            setReadonly(false);
        };
    }, []);

    useEffect(() => {
        (async () => await isDomainExistAction())();
    }, [isDomainExistAction]);

    useEffect(() => {
        if (domain.length) {
            setItems(domain.map(({ domain }) => domain));
        }
    }, [domain]);

    return (
        <DomainExistContext.Provider value={{ isDomainExistAction, domain, loading }}>
            <Outlet />
        </DomainExistContext.Provider>
    );
};

export const useDomainExist = () => {
    return useContext(DomainExistContext);
};
