import { useIsDomainInBlacklist as useMvx } from 'domain/useIsDomainBlacklisted';
import { useIsDomainInBlacklist as useEth } from 'domain-eth/useIsDomainBlacklisted';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { Platform } from 'providers/PlatformContext/Platforms';

export const useIsDomainInBlacklist = () => {
    const mvx = useMvx();
    const eth = useEth();

    const [platform] = usePlatform();
    if (platform === Platform.MVX) return mvx;
    if (platform === Platform.MATIC) return eth;

    throw new Error(`Selected platform ${platform} is not supported`);
}