import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import { notification, Tooltip } from 'antd';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import { useMediaQuery } from 'react-responsive';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchBox.css';
import { useGetRootDomains, useIsDomainInBlacklist } from 'hooks';
import { getRootDomainRegExp } from 'domain/shared/regExp';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { Platform } from 'providers/PlatformContext/Platforms';

export const SearchBox = ({ mode = 'default' }: { mode?: 'white' | 'default' }) => {
    const [platform] = usePlatform();
    const [search, setSearch] = useState<string>('');
    const navigate = useNavigate();
    const checkIsDomainInBlacklist = useIsDomainInBlacklist();
    const getRootDomains = useGetRootDomains();
    const [loading, setLoading] = useState(false);

    const isDomainInBlacklistAction = useCallback(async (preparedDomainName: string) => {
        setLoading(true);
        const isInList = await checkIsDomainInBlacklist(preparedDomainName);
        setLoading(false);
        return isInList;
    }, []);

    const handleChange = ({ target }: { target: HTMLInputElement }) => {
        setSearch(target.value);
    };

    const handleSearch = async () => {
        let name = encodeURIComponent(search.toLowerCase());
        const rootDomains = await getRootDomains();
        const preparedName = decodeURIComponent(name).replace(new RegExp(getRootDomainRegExp(rootDomains || []), 'gm'), '');
        const isInBlacklist = await isDomainInBlacklistAction(preparedName);
        if (isInBlacklist) {
            notification.error({
                key: 'blacklist-err',
                message: 'Domain is in blacklist.',
            });
        } else {
            navigate(`/${platform}/search/${preparedName}`);
        }
    };

    const isButtonDisabled = useMemo(() => {
        const minLength = platform === Platform.MVX ? 3 : 1;
        const preparedSearch = search.trim();
        return preparedSearch.length < minLength || preparedSearch.length > 20;
    }, [platform, search]);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <div
            className={`search-field search-field--${mode}`}
            style={{
                display: 'flex',
                alignItems: 'center', // Center items vertically
                marginBottom: '20px',
                marginTop: '10px'
            }}
        >
            <Input
                value={search}
                size="small"
                placeholder="Search Here"
                onChange={handleChange}
                onPressEnter={isButtonDisabled ? undefined : handleSearch}
                style={{
                    flex: 1, // Fill available space
                    height: '25px', // Adjust input height
                    fontSize: '12px', // Adjust input font size
                    borderRadius:'0px',
                    marginTop:'12px',
                    width:'100px',
                }}
            />
            <Button
                disabled={isButtonDisabled}
                size={isMobile ? "small" : "middle"}
                onClick={handleSearch}
                style={{
                    fontSize: '12px',
                    background: 'blue',
                    height: '25px', // Adjust button height
                    textAlign:'center',
                    justifyContent:'center',
                    borderColor:'blue',
                    borderRadius:'0px',
                    marginTop:'12px',
                }}
            >
                <SearchOutlined style={{ color: '#adb5bd', fontSize: '12px' }} />
            </Button>
        </div>
    );
};
