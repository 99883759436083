import { useGetRootDomains as useMvx } from 'domain/useGetRootDomains';
import { useGetRootDomains as useEth } from 'domain-eth/useGetRootDomains';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { Platform } from 'providers/PlatformContext/Platforms';

export const useGetRootDomains = () => {
    const mvx = useMvx();
    const eth = useEth();

    const [platform] = usePlatform();
    if (platform === Platform.MVX) return mvx;
    if (platform === Platform.MATIC) return eth;

    throw new Error(`Selected platform ${platform} is not supported`);
}