import { formatEther } from '@ethersproject/units';
import { readContract } from '@wagmi/core';
import { domainStorageAddressEt } from 'config';
import domainStorageAbi from './DomainStorage.abi.json';

export const useGetEthPrice = () => {
    const getPrice = async (records: string[]) => {
        try {
            const price = await readContract({
                address: domainStorageAddressEt,
                abi: domainStorageAbi,
                functionName: 'getPrice',
                args: [records],
            }) as any;
            return price && parseFloat(formatEther(price));
        } catch (error:any) {
            console.error('Unable to call getPrice', error)
        }
    }

    return getPrice;
}