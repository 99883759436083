import React, { useEffect, useState } from 'react';
import { Layout, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const Wallet: React.FC = () => {
    const [bearerToken, setBearerToken] = useState(null);
    const navigate = useNavigate();
    const ID = process.env.REACT_APP_PARTNER_ID;
    const KEY = process.env.REACT_APP_PARTNER_KEY ;
    
    useEffect(() => {
        const getBearerTokenFromURL = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const uri=JSON.parse(decodeURI(window.location.search)?.split('?')[1])
            const token = uri?.bearerToken || '';
            console.log('Token from URL:', token);
            if (token) {
                setBearerToken(token);
            }
        };
        getBearerTokenFromURL();
        
    }, []); 
    
    useEffect(() => {
        console.log('Bearer Token:', bearerToken); // Log the bearer token value
    }, [bearerToken]);

    const handleGoBack = () => {
        navigate('/');
    };

    return (
        <Layout>
            <Button 
                type="primary" 
                onClick={handleGoBack} 
                style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 1000 }}
            >
                Back
            </Button>
            {bearerToken && (
                <>
                <iframe
                    src={`https://iframe-v2-phi.vercel.app/?token=${bearerToken}&partnerId=${ID}&partnerKey=${KEY}`}
                    title="Wallets"
                    style={{ border: 'none', width: '100%', height: '100vh' }}
                    allow="clipboard-read; clipboard-write"
                ></iframe>
                </>
            )}
        </Layout>
    );
};

export default Wallet;
