import { useState, useEffect } from 'react';
import { SwapWidget } from '@dodoex/widgets';
import "../App.css";
import Navbar from "../components/Navbar";
import { Layout, Spin, Row} from 'antd';
import logo3 from './../assets/twitter.png';
import logo4 from './../assets/icons8-discord-50.png';


function Swap(){
    const [swapLoading, setSwapLoading] = useState(true);

    // Simulating API call delay for demonstration purposes
    useEffect(() => {
        const timer = setTimeout(() => {
            setSwapLoading(false); // Simulate data fetching completion
        }, 3000); // Adjust this delay as needed for your actual API call

        return () => clearTimeout(timer); // Clear the timer on unmount
    }, []);
    return(
        <>
        <Layout className="home-layout" style={{ background:"black", backgroundSize: 'cover' }}>
         <div style={{margin:'10px'}}>      
        <Navbar>
        <div className='logo'><span className="bold">XDomain</span><span className="slim">Swap</span></div>
        </Navbar>
        </div> 
        <div className='swap-container' style={{paddingTop:'30px'}}>
        {swapLoading ? (
                    <Spin size="large" />
                ) : (
            <SwapWidget  colorMode="dark"
        apikey={"ed1d045865e81fa9cf"} 
        width={400}
        height={500}
        defaultChainId={137}
        feeRate={1500000000000000}
        rebateTo='0x2Ba1633338dDD2Ab37fbc95ea615BA98f0445380'
        crossChain={true}
        popularTokenList={[
            {
                chainId:137,
                address:'0x162539172b53e9a93b7d98fb6c41682de558a320',
                name:'GONE',
                decimals:18,
                symbol:"GONE",
                logoURI:"https://s2.coinmarketcap.com/static/img/coins/64x64/28863.png"
                
            },
            {
                chainId:137,
                address:'0x7aefff599570dec2f3dbbc2ace3cb1f8206749eb',
                name:'MOONFLOW',
                decimals:18,
                symbol:"MOON",
                logoURI:"https://assets.coingecko.com/coins/images/34862/standard/coin_big_white_logo_%281%29.png?1706354814"
                
            }
        ]

        }
        />
                )}
        </div>
        
        <Row justify='center'>
            <div style={{color: 'white', padding: '15px',marginTop:'40px', fontSize: '20px'}}>Join Our Community</div>
            </Row>
        <Row style={{ padding: '20px', justifyContent: 'center' }}>
        
            <Row justify="center">
        <a href="https://twitter.com/XDomainServices" target="_blank" rel="noopener noreferrer">
            <img src={logo3} alt="Twitter Logo" style={{ width: '40px', height: '40px', marginRight: '20px' }} />
        </a>
        <a href="https://discord.com/invite/u7sWaxJrFE" target="_blank" rel="noopener noreferrer">
            <img src={logo4} style={{ width: '40px', height: '40px' }} />
        </a>
    </Row>
    </Row>
             </Layout>
             
        </>
       

        
    )
}
export default Swap;