import { Alert, Checkbox, notification, Typography } from 'antd';
import { Row, Col } from 'antd';
import { RecordCard } from 'components/RecordCard/RecordCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetRootDomains, useIsDomainExist, useIsDomainInReservedList } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { Loading } from 'components/Loading';
import { getRootDomainRegExp } from 'domain/shared/regExp';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { useCart } from 'providers/cart/useCart';
import { Platform } from 'providers/PlatformContext/Platforms';

const isDomainNameCorrect = (name: string) => /^[a-zA-Z0-9]*$/.test(name);

export const SearchResult = () => {
    const navigate = useNavigate();
    const { name } = useParams();
    const [platform] = usePlatform();
    const checkIsDomainExist = useIsDomainExist();
    const checkIsDomainInReservedList = useIsDomainInReservedList();
    const getRootDomains = useGetRootDomains();
    const [domains, setDomains] = useState<any[]>();
    const [loading, setLoading] = useState(false);
    const [isDomainNameError, setIsDomainNameError] = useState(false);
    const [rootDomainsList, setRootDomainsList] = useState<string[]>([]);
    const { items, addItem, removeItem } = useCart();

    const domainStateAction = useCallback(async (preparedDomainName: string) => {
        setLoading(true);
        const rootDomains = (await getRootDomains()) || [];
        setRootDomainsList(rootDomains || []);
        if (rootDomains.length) {
            const preparedRequestArray = rootDomains.map(domain => checkIsDomainExist([`${preparedDomainName}.${domain}`]).then(res => res[0]));
            const isExistResult = (await Promise.all(preparedRequestArray)) || [];
            const preparedIsReservedRequestArray = rootDomains.map(domain => checkIsDomainInReservedList(`${preparedDomainName}.${domain}`));
            const isReservedResult = (await Promise.all(preparedIsReservedRequestArray)) || [];
            const result = isExistResult.map(isExistItem => {
                const reservedItem = isReservedResult.find(el => el?.domain === isExistItem?.domain);
                return {
                    ...isExistItem,
                    isInReservedList: reservedItem?.isInReservedList,
                };
            });
            setDomains(result!);
        } else {
            notification.warning({
                key: 'root-Domains',
                message: 'There are no available root domains.',
            });
            setDomains(undefined);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        if (name) {
            const preparedName = decodeURIComponent(name).replace(new RegExp(getRootDomainRegExp(rootDomainsList), 'gm'), '');
            const isNameCorrect = isDomainNameCorrect(preparedName);

            if (!isNameCorrect) {
                setIsDomainNameError(!isNameCorrect);
                return;
            } else {
                setIsDomainNameError(false);
                (async () => await domainStateAction(preparedName))();
            }
        }
    }, [name]);

    const toggleDomainInCart = useCallback(
        (domain: string, checked: boolean) => {
            if (checked) {
                addItem(domain);
            } else {
                removeItem(domain);
            }
        },
        [addItem, removeItem]
    );

    const handleDomainClick = useCallback(
        (domain: string, isExist: boolean) => {
            if (platform === Platform.MATIC) {
                if (isExist) {
                    navigate(`/${platform}/names/${domain}/details`);
                    return;
                }
                const updatedItems = addItem(domain);
                const domainList = updatedItems.join(',');
                navigate(`/${platform}/names/${domainList}/register`);
            } else {
                navigate(`/${platform}/names/${domain}/${isExist ? 'details' : 'register'}`);
            }
        },
        [addItem, navigate, platform, items]
    );

    const renderContent = () =>
        typeof domains === 'undefined' ? (
            <Alert message="Unable to check if record exist" type="warning" />
        ) : (
            <>
                <div>
                    <Typography.Title level={3}>Names</Typography.Title>
                </div>
                <Row gutter={[8, 8]}>
                    {domains.map(({ domain, isExist, isInReservedList }, index) => {
                        let domainStatus = 'Available';
                        if (isExist) domainStatus = 'Unavailable';
                        if (isInReservedList) domainStatus = 'Reserved';
                        const isRecordExist = isExist || isInReservedList;

                        return (
                            <Col xs={24} key={`${name}.${domain}`}>
                                <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        if (isInReservedList) {
                                            return;
                                        }
                                        handleDomainClick(domain, isExist);
                                    }}
                                >
                                    <RecordCard isRecordExist={isRecordExist}>
                                        <Row align="middle" style={{ gap: '20px' }}>
                                            <Row align="middle" justify="space-between" style={{ flex: 1 }}>
                                                <Typography.Text style={{ fontSize: '20px' }}>{domain}</Typography.Text>
                                                <Typography.Text style={{ color: '#9999' }}>{domainStatus}</Typography.Text>
                                            </Row>
                                            {domainStatus === 'Available' && platform === Platform.MATIC ? (
                                                <Checkbox
                                                    checked={items.includes(domain)}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                    }}
                                                    onChange={c => {
                                                        toggleDomainInCart(domain, c.target.checked);
                                                    }}
                                                />
                                            ) : null}
                                        </Row>
                                    </RecordCard>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </>
        );

    return isDomainNameError ? <Alert message={`Domain ${name} is not valid.`} type="error" /> : loading ? <Loading /> : renderContent();
};
