import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { BytesValue, ContractFunction, ResultsParser, Address } from '@multiversx/sdk-core/out';
import {  createSmartContract } from "./smartContract";


const resultsParser = new ResultsParser();

export const useGetDomainOwner = () => {
    const { network } = useGetNetworkConfig();
    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const contract = createSmartContract({});

    const getDomainOwner  = async ( record: string ) => {
        try {
            const query = contract.createQuery({
                func: new ContractFunction('getDomainOwner'),
                args: [ BytesValue.fromUTF8(record)]
            })
            const queryResponse = await proxy.queryContract(query);

            const endpointDefinition = contract.getEndpoint('getDomainOwner');
            const {firstValue}= resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );
            const result = firstValue?.valueOf();
            
            return new Address(result).bech32();
        } catch (error:any) {
            console.error('Unable to call getDomainOwner', error)
        }
    }

    return getDomainOwner ;
}