import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import React from 'react';
import { apiTimeout, NETWORK, walletConnectV2ProjectId } from 'config';

export const withMvxDappProvider = (WrappedComponent: any) => (props: any) => {
    return (
        <DappProvider
            environment={NETWORK}
            customNetworkConfig={{
                name: 'customConfig',
                apiTimeout,
                walletConnectV2ProjectId,
            }}
        >
            <WrappedComponent {...props} />
        </DappProvider>
    );
};
