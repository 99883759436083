import axios from "axios";

interface IGetESDList {
    address: string,
    url: string,
    token:string
}

export const getESDTList =async ({url,address, token}:IGetESDList) => {
    try {
        const {data} = await axios.get(`${url}/accounts/${address}/tokens?${new URLSearchParams({identifier:token})}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return data;
    } catch (error:any) {
        throw error.message || 'Unable to call getESDTList';
    }
}