import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import { notification, Tooltip } from 'antd';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import { useMediaQuery } from 'react-responsive';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchBox.css';
import { useGetRootDomains, useIsDomainInBlacklist } from 'hooks';
import { getRootDomainRegExp } from 'domain/shared/regExp';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { Platform } from 'providers/PlatformContext/Platforms';

export const SearchBox = ({ mode = 'default' }: { mode?: 'white' | 'default' }) => {
    const [platform] = usePlatform();
    const [search, setSearch] = useState<string>('');
    const navigate = useNavigate();
    const checkIsDomaInBlacklist = useIsDomainInBlacklist();
    const getRootDomains = useGetRootDomains();
    const [loading, setLoading] = useState(false);

    const isDomaInBlacklistAction = useCallback(async (preparedDomainName: string) => {
        setLoading(true);
        const isInList = await checkIsDomaInBlacklist(preparedDomainName);
        setLoading(false);
        return isInList;
    }, []);

    const handleChange = ({ target }: { target: HTMLInputElement }) => {
        setSearch(target.value);
    };

    const handleSearch = async () => {
        let name = encodeURIComponent(search.toLowerCase());
        const rootDomains = await getRootDomains();
        const preparedName = decodeURIComponent(name).replace(new RegExp(getRootDomainRegExp(rootDomains || []), 'gm'), '');
        const isInBlackList = await isDomaInBlacklistAction(preparedName);
        if (isInBlackList) {
            notification.error({
                key: 'black-list-err',
                message: 'Domain is in blacklist.',
            });
        } else {
            navigate(`/${platform}/search/${preparedName}`);
        }
    };

    const isButtonDisabled = useMemo(() => {
        const minLength = platform === Platform.MVX ? 3 : 1;
        const preparedSearch = search.trim();
        return preparedSearch.length < minLength || preparedSearch.length > 20;
    }, [platform, search]);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return (
        <div
        className={`search-field search-field--${mode}`}
        style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row', // Use column on mobile, row on desktop
            alignItems: isMobile ? 'center' : 'flex-start', // Center on mobile, align start on desktop
            marginBottom: '20px',
            marginTop:'10px'
        }}
    >
        <Input
            value={search}
            size="large"
            placeholder="Search Your XDomain"
            prefix={<SearchOutlined style={{ color: '#adb5bd', fontSize: '25px' }} />}
            onChange={handleChange}
            onPressEnter={isButtonDisabled ? undefined : handleSearch}
            style={{
                borderRadius: '22px',
                height: '50px',
                marginBottom: isMobile ? '10px' : '0', // Add margin bottom on mobile only
                boxSizing: 'border-box',
            }}
        />
        {isMobile && ( // Render button only on mobile
            <Tooltip title="*  must be contained between 3 and 20 characters">
                <Button
                    disabled={isButtonDisabled}
                    size="large"
                    onClick={handleSearch}
                    loading={loading}
                    style={{
                        borderRadius: '30px',
                        fontSize: '25px',
                        height: '50px',
                        width:'130px',
                        background: 'blue',
                        borderColor:'blue',
                        color:"white",
                        fontWeight:'bold',
                    }}
                >
                    Search
                </Button>
            </Tooltip>
        )}
        {!isMobile && ( // Render button only on desktop
            <Tooltip title="*  must be contained between 3 and 20 characters">
                <Button
                    disabled={isButtonDisabled}
                    size="middle"
                    onClick={handleSearch}
                    loading={loading}
                    style={{
                        borderRadius: '30px',
                        fontSize: '25px',
                        height: '50px',
                        width:'130px',
                        marginLeft: '10px',
                        background: 'blue',
                        borderColor:'blue',
                        color:'white',
                        fontWeight:'bold',
                    }}
                >
                    Search
                </Button>
            </Tooltip>
        )}
    </div>
    );
};