import { useGetResolver as useMvx } from 'domain/useGetResolver';
import { useGetResolver as useEth } from 'domain-eth/useGetResolver';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { Platform } from 'providers/PlatformContext/Platforms';

export const useGetResolver = (existsOnPlatform?: Platform) => {
    const mvx = useMvx();
    const eth = useEth();

    const [platform] = usePlatform();
    const activePlatform = existsOnPlatform || platform;

    if (activePlatform === Platform.MVX) return mvx;
    if (activePlatform === Platform.MATIC) return eth;

    throw new Error(`Selected platform ${platform} is not supported`);
}