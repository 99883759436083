import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { walletConnectV2ProjectId } from 'config';
import { configureChains, WagmiConfig } from 'wagmi';
import { polygon, polygonMumbai } from 'wagmi/chains';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

const { chains } = configureChains(
    [polygon],
    [
        jsonRpcProvider({
            rpc: () => ({
                http: `https://polygon-rpc.com/`,
            }),
        }),
    ]
);

const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId: walletConnectV2ProjectId,
})

createWeb3Modal({
    wagmiConfig,
    projectId: walletConnectV2ProjectId,
    chains,
    featuredWalletIds: [
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
        '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
        'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
    ],
});

export const withEthWagmiProvider = (WrappedComponent: any) => (props: any) => {
    return (
        <>
            <WagmiConfig config={wagmiConfig}>
                <WrappedComponent {...props} />
            </WagmiConfig>
        </>
    );
};
