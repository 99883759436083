import { readContract } from '@wagmi/core';
import addressResolverAbi from './AddressResolver.abi.json';
import { resolverAddressEt, AddressResolver  } from 'config';

export const useGetResolverData = () => {
    const getResolverData = async (record: string) => {
        try {
            let resolverData;
            if (record.endsWith('.drill')) {
                // If it's a .drill domain, query the drillAddress
                resolverData = await readContract({
                    address: AddressResolver, // Assuming you have a drillAddress defined
                    abi: addressResolverAbi,
                    functionName: 'getWalletAddress',
                    args: [record],
                }) as any;
            } else {
                // Otherwise, query the resolverAddressEt
                resolverData = await readContract({
                    address: resolverAddressEt, // Assuming you have a resolverAddressEt defined
                    abi: addressResolverAbi,
                    functionName: 'getWalletAddress',
                    args: [record],
                }) as any;
            }
            return resolverData;
        } catch (error: any) {
            console.error('Unable to call getWalletAddress', error);
        }
    }

    return getResolverData;
}
