import React, { useCallback, useState } from 'react';
import { Button, Row, Select } from 'antd';
import { useGetIsLoggedIn } from 'hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import { ConnectModal } from './ConnectModal/ConnectModal';
import './Connect.css';
import { useLocation } from 'react-router-dom';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { Platform } from 'providers/PlatformContext/Platforms';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useDisconnect } from 'wagmi';
import MVXLogo from '../../assets/Mvx.png';
import PolygonLogo from '../../assets/Polygon.png';
import KometWallet from '../../pages/Wallet'; 

interface IConnect {
    children: React.ReactNode;
    contentClassName?: string;
    showSelect?: boolean;
}

const platforms = [
    { value: Platform.MVX, label: 'MVX', image: MVXLogo },
    { value: Platform.MATIC, label: 'Polygon', image: PolygonLogo },
];

export const Connect = ({ children, contentClassName = '', showSelect }: IConnect) => {
    const isLoggedIn = useGetIsLoggedIn();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isWalletOpen, setIsWalletOpen] = useState(false);
    const { pathname } = useLocation();
    const [platform, setPlatform] = usePlatform();
    const { open } = useWeb3Modal();
    const { disconnect } = useDisconnect();

    const handleConnect = useCallback(async () => {
        if (platform === Platform.MVX) {
            setIsModalOpen(true);
            return;
        }
        if (platform === Platform.MATIC) {
            open();
        }
    }, [platform]);

    const handleLogout = useCallback(() => {
        if (platform === Platform.MVX) {
            logout(`${window.location.origin}${pathname}`);
            return;
        }
        if (platform === Platform.MATIC) {
            disconnect();
        }
    }, [platform]);

    return (
        <>
        
            <div className={contentClassName}>
                {children}
                <Row justify="center">
                    {showSelect && (
                        <div style={{ marginRight: '10px', marginBottom: '10px' }}>
                            <Select
                                className="select-btn"
                                size="large"
                                defaultValue={platform}
                                onChange={setPlatform}
                            >
                                {platforms.map(option => (
                                    <Select.Option key={option.value} value={option.value}>
                                        <img src={option.image} alt={option.label} style={{ width: '20px', marginRight: '5px' }} />
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    )}
                   
                    <Button
                        type="primary"
                        className="connect-btn"
                        size="large"
                        onClick={isLoggedIn ? handleLogout : handleConnect}
                    >
                        {isLoggedIn ? 'Disconnect' : 'Connect'}
                    </Button>
                    
                </Row>
            </div>
            
            {isModalOpen && <ConnectModal onClose={() => setIsModalOpen(false)} />}
            {isWalletOpen && (
                <div style={{ position: 'fixed', backgroundColor: 'black', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
                    <KometWallet onClose={() => setIsWalletOpen(false)} />
                </div>
            )}
        </>
    );
};
