import { Card, Row, Typography } from 'antd';
import { ExtensionLoginButton, WalletConnectLoginButton, WebWalletLoginButton } from '@multiversx/sdk-dapp/UI';
import Col from 'antd/es/grid/col';
import { Modal } from 'antd';
import './ConnectModal.css';

import connectWalletImg from '../../../assets/connectWallet.png';
import maiarWalletImg from '../../../assets/maiar.png';
import { useLocation } from 'react-router-dom';
import ArrowRightOutlined from '@ant-design/icons/lib/icons/ArrowRightOutlined';
import { useEffect } from 'react';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';

interface IConnectModal {
    onClose: any;
}

export const ConnectModal = ({ onClose }: IConnectModal) => {
    const isLoggedIn = useGetIsLoggedIn();
    const { pathname } = useLocation();

    useEffect(() => {
        if (isLoggedIn) {
            onClose();
        }
    }, [isLoggedIn]);

    return (
        <Modal open={true} destroyOnClose={true} onCancel={onClose} footer={false} width={800} closable={false} zIndex={500}>
            <Row gutter={[8, 8]}>
                <Col xs={24}>
                    <WebWalletLoginButton buttonClassName="connect-servive-btn" callbackRoute={pathname}>
                        <Card hoverable className="connect-service">
                            <Row align="middle" className="row">
                                <Row style={{ alignItems: 'baseline' }}>
                                    <div className="connect-service-logo">
                                        <img src={connectWalletImg} alt="connectImg" />
                                    </div>
                                    <div className="connect-service-title">
                                        <Typography.Text strong ellipsis>
                                            MultiversX Web Wallet
                                        </Typography.Text>
                                    </div>
                                </Row>
                                <ArrowRightOutlined />
                            </Row>
                        </Card>
                    </WebWalletLoginButton>
                </Col>
                <Col xs={24}>
                    <ExtensionLoginButton buttonClassName="connect-servive-btn">
                        <Card hoverable className="connect-service">
                            <Row className="row" align="middle">
                                <Row style={{ alignItems: 'baseline' }}>
                                    <div className="connect-service-logo">
                                        <img src={connectWalletImg} alt="connectImg" />
                                    </div>
                                    <div className="connect-service-title">
                                        <Typography.Text strong>MultiversX DeFi Wallet</Typography.Text>
                                    </div>
                                </Row>
                                <ArrowRightOutlined />
                            </Row>
                        </Card>
                    </ExtensionLoginButton>
                </Col>
                <Col xs={24}>
                    <WalletConnectLoginButton buttonClassName="connect-servive-btn" logoutRoute={pathname} isWalletConnectV2={true}>
                        <Card hoverable className="connect-service">
                            <Row className="row" align="middle">
                                <Row style={{ alignItems: 'baseline' }}>
                                    <div className="connect-service-logo">
                                        <img src={maiarWalletImg} alt="connectImg" />
                                    </div>
                                    <div className="connect-service-title">
                                        <Typography.Text strong>xPortal</Typography.Text>
                                    </div>
                                </Row>
                                <ArrowRightOutlined />
                            </Row>
                        </Card>
                    </WalletConnectLoginButton>
                </Col>
            </Row>
        </Modal>
    );
};
                
