import { Form, Select, Typography } from 'antd';
import { useDomainExist } from 'components/domainExist/DomainExistContext';
import { useGetESDTPayments } from 'domain/esdt/useGetESDTPayments';
import { Platform } from 'providers/PlatformContext/Platforms';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface IPaymentSelect {
    paymentMethod?: string;
    onChange: Function;
}

export const PAYMENT_METHODS = {
    EGLD: 'egld',
    VOUCHER: 'voucher',
    RARE: 'rare',
    HYPE: 'hype',
    MATIC_REGULAR: 'matic_regular',
    MATIC_CUSTOM:'matic_custom',
    MATIC_NFT:'matic_nft'
};

const selectOptions = [
    { label: 'Egld', value: PAYMENT_METHODS.EGLD },
    { label: 'Voucher', value: PAYMENT_METHODS.VOUCHER },
];

const selectOptionsEth = [
    { label: 'Regular register', value: PAYMENT_METHODS.MATIC_REGULAR },
    { label: 'Custom register', value: PAYMENT_METHODS.MATIC_CUSTOM },
    { label: 'Register for free', value: PAYMENT_METHODS.MATIC_NFT }
];


export const PaymentSelect = ({ paymentMethod, onChange }: IPaymentSelect) => {
    const { domain } = useDomainExist();
    const isHype = domain.length === 1 && domain[0].domain?.includes('.hype');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
    const getESDTPayments = useGetESDTPayments();
    const [platform] = usePlatform();
    const [form] = Form.useForm();

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (platform === Platform.MVX) {
                const esdtPaymentTokens = await getESDTPayments();
                const esdtOptions = (esdtPaymentTokens || []).map(token => ({
                    value: `${PAYMENT_METHODS.RARE}::${token}`,
                    label: `${token}`,
                }));
                let hypeOpt = esdtOptions.filter(opt => opt.label.includes('HYPE'));
                const opt = isHype ? [{ label: 'Free for you!', value: PAYMENT_METHODS.HYPE }, ...hypeOpt] : [...selectOptions, ...esdtOptions];
                setOptions(opt);
                onChange(PAYMENT_METHODS.VOUCHER);
                form.setFieldsValue({ payment_method: PAYMENT_METHODS.VOUCHER });
            }
            if (platform === Platform.MATIC) {
                // Check if the domain is a .drill domain
                const isDrillDomain = domain.length === 1 && domain[0].domain?.includes('.drill');
                if (isDrillDomain) {
                    setOptions(selectOptionsEth.filter(opt => opt.value === PAYMENT_METHODS.MATIC_CUSTOM));
                    onChange(PAYMENT_METHODS.MATIC_CUSTOM);
                    form.setFieldsValue({ payment_method: PAYMENT_METHODS.MATIC_CUSTOM });
                } else {
                    setOptions(selectOptionsEth);
                    onChange(PAYMENT_METHODS.MATIC_REGULAR);
                    form.setFieldsValue({ payment_method: PAYMENT_METHODS.MATIC_REGULAR });
                } 
            } else{
                setOptions(selectOptionsEth);
                    onChange(PAYMENT_METHODS.MATIC_NFT);
                    form.setFieldsValue({ payment_method: PAYMENT_METHODS.MATIC_NFT });
            }
            setLoading(false);
        })();
    }, [platform]);

    const onSelect = (value: string) => {
        onChange(value);
    };

    return (
        <Form form={form} className="payment-select-form">
            <Form.Item
                name="payment_method"
                label={<Typography.Paragraph style={{ fontSize: '16px', margin: 0 }}>Please select payment method</Typography.Paragraph>}
            >
                <Select loading={loading} value={paymentMethod} style={{ width: '250px' }} options={options} onChange={onSelect} />
            </Form.Item>
        </Form>
    );
};
