import { Card } from 'antd';
import React from 'react';
import './RecordCard.css';

interface IRecordCard {
    children: React.ReactNode;
    isRecordExist: boolean;
}

export const RecordCard = ({ children, isRecordExist }: IRecordCard) => (
    <Card className={`card-${isRecordExist ? 'unavailable' : 'available'}`}>{children}</Card>
);
