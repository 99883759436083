import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { useAccount } from 'wagmi';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { useMemo } from 'react';
import { Platform } from 'providers/PlatformContext/Platforms';
import { Typography } from 'antd';

const EllipsisMiddle: React.FC<{ suffixCount: number; children: string }> = ({ suffixCount, children }) => {
    const start = children.slice(0, children.length - suffixCount).trim();
    const suffix = children.slice(-suffixCount).trim();
    return (
        <Typography.Text style={{ maxWidth: '100%' }} ellipsis={{ suffix }}>
            {start}
        </Typography.Text>
    );
};

export const AddressComponent = () => {
    const [platform] = usePlatform();
    const { address } = useGetAccountInfo();
    const { address: ethAddress } = useAccount();

    const addressForPlatform = useMemo(() => {
        return platform === Platform.MVX ? address : ethAddress;
    }, [address, ethAddress, platform]);

    return (
        <div style={{ maxWidth: '100%' }}>
            <EllipsisMiddle suffixCount={12}>{addressForPlatform || ''}</EllipsisMiddle>
        </div>
    );
};
