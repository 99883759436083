import { ContractFunction, ResultsParser, TokenIdentifierValue, U32Value } from "@multiversx/sdk-core/out";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { createSmartContract } from "domain/smartContract";


const resultsParser = new ResultsParser();

export const useGetEsdtPrice = () => {
    const { network } = useGetNetworkConfig();
    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const contract = createSmartContract({});

    const getEsdtPrice = async (token:string, domainLength:number) => {
        try {
            const query = contract.createQuery({
                func: new ContractFunction('getEsdtPrice'),
                args:[TokenIdentifierValue.esdtTokenIdentifier(token),new U32Value(domainLength)]
            })
            const queryResponse = await proxy.queryContract(query);
            const endpointDefinition = contract.getEndpoint('getEsdtPrice');
            const {firstValue}= resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );
            let price :number=Number(firstValue?.valueOf());

            return price;
        } catch (error) {
            console.error('Unable to call getEsdtPrice', error);
        }
        
    }

    return getEsdtPrice;
}