import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import {  ContractFunction,  ResultsParser } from '@multiversx/sdk-core/out';
import { createSmartContract } from "./smartContract";

const resultsParser = new ResultsParser();

const parseUint8Value = (value: Uint8Array) => Buffer.from(value).toString('utf8');

export const useGetRootDomains = () => {
    const { network } = useGetNetworkConfig();
    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const contract = createSmartContract({});

    const getRootDomains = async () => {
        try {
            const query = contract.createQuery({
                func: new ContractFunction('getRootDomains'),
            })
            const queryResponse = await proxy.queryContract(query);
            const endpointDefinition = contract.getEndpoint('getRootDomains');
            const {firstValue}= resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );
            

            let rootDomains :string[]= [];
            firstValue?.valueOf().length && (rootDomains = firstValue?.valueOf().map(parseUint8Value));

            return rootDomains;
        } catch (error) {
            console.error('Unable to call getRootDomains', error);
        }
        
    }

    return getRootDomains;
}