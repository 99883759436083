import { ShoppingCartOutlined } from '@ant-design/icons';
import './CartIcon.css';
import { useCart } from 'providers/cart/useCart';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { Platform } from 'providers/PlatformContext/Platforms';

export const CartIcon = ({ showModal }: { showModal: () => void }) => {
    const [platform] = usePlatform();
    const { items } = useCart();

    if (platform !== Platform.MATIC) {
        return null;
    }

    return (
        <div className="shopping-cart" onClick={showModal}>
            <ShoppingCartOutlined style={{ fontSize: '28px' }} />
            {items.length ? <div className="shopping-cart-counter">{items.length}</div> : null}
        </div>
    );
};
