export const getCorrectNonces = (nftList: any[], voucherNonces: number[], voucherId: string) => {
    if (!nftList.length) return [];

    const correctNonces: any[] = [];
    nftList.forEach(nftItem => {
        const nftCollection = nftItem.collection;

        if (nftCollection === voucherId) {
            const nftNonce = nftItem.nonce;
            const correctNonce = voucherNonces.find(nonce => nonce === nftNonce);
            correctNonce && correctNonces.push(correctNonce);
        }
    });

    return correctNonces;
};