import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { BytesValue, ContractFunction, ResultsParser, Address } from '@multiversx/sdk-core/out';
import {  ABI_TYPE, createSmartContract } from "./smartContract";

const resultsParser = new ResultsParser();

export const useGetResolver = () => {
    const { network } = useGetNetworkConfig();
    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const contract = createSmartContract({});

    const getResolver = async ( record: string ) => {
        try {
            const query = contract.createQuery({
                func: new ContractFunction('getResolver'),
                args: [ BytesValue.fromUTF8(record)]
            })
            const queryResponse = await proxy.queryContract(query);

            const endpointDefinition = contract.getEndpoint('getResolver');
            const {firstValue}= resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );
            const result = firstValue?.valueOf();
            
            return new Address(result).bech32();
        } catch (error:any) {
            console.error('Unable to call getResolver', error)
        }
    }

    return getResolver;
}

export const getResolverContract = async (record: string, getResolver:Function) => {
    const address = await getResolver(record);
    
    return createSmartContract({ address: address, abiType: ABI_TYPE.RESOLVER });
};