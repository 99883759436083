import { domainStorageAddressEt, DomainStorage } from 'config';
import domainStorageAbi from './DomainStorage.abi.json';
import { readContract } from '@wagmi/core';

export const useGetRootDomains = () => {
    const getRootDomains = async () => {
        try {
            const [rootDomainsDrill, rootDomainsEt] = await Promise.all([
                readContract({
                    address: DomainStorage,
                    abi: domainStorageAbi,
                    functionName: 'getAllRootDomains',
                }).then(data => data as string[]), // Explicit cast to string[]
                readContract({
                    address: domainStorageAddressEt,
                    abi: domainStorageAbi,
                    functionName: 'getAllRootDomains',
                }).then(data => data as string[]) // Explicit cast to string[]
            ]);

            // Combine both arrays and convert to string[]
            const rootDomains = [...rootDomainsDrill, ...rootDomainsEt].map(String);

            return rootDomains;
        } catch (error: any) {
            console.error('Unable to call getRootDomains', error);
            // Handle error if needed
        }
    };

    return getRootDomains;
};

