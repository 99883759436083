import { createContext } from 'react';

export const CartContext = createContext<{
    items: string[];
    readonly: boolean;
    addItem: (item: string) => string[];
    removeItem: (item: string) => void;
    clear: () => void;
    setItems: (items: string[]) => void;
    setReadonly: (isReadonly: boolean) => void; 
}>({
    items: [],
    readonly: false,
    addItem: () => [],
    removeItem: () => {},
    clear: () => {},
    setItems: () => {},
    setReadonly: () => {},
});
