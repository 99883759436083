import axios from "axios";

interface IGetESDdetails {
    token: string,
    address: string,
    url:string
}

export const getESDTdetails = async ({ token, address,url }: IGetESDdetails) => {
    try {
        const {data} = await axios.get(`${url}/accounts/${address}/tokens/${token}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return data;
    } catch (error:any) {
        throw error.message || 'Unable to call getESDdetails';
    }

}