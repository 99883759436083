import { useGetDomainOwner as useMvx } from 'domain/useGetDomainOwner';
import { useGetDomainOwner as useEth } from 'domain-eth/useGetDomainOwner';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { Platform } from 'providers/PlatformContext/Platforms';

export const useGetDomainOwner = (existsOnPlatform?: Platform) => {
    const mvx = useMvx();
    const eth = useEth();

    const [platform] = usePlatform();

    const activePlatform = existsOnPlatform || platform;

    if (activePlatform === Platform.MVX) return mvx;
    if (activePlatform === Platform.MATIC) return eth;

    throw new Error(`Selected platform ${platform} is not supported`);
}