import { createSmartContract } from 'domain/smartContract';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';
import { useEffect, useState } from 'react'; 


const resultsParser = new ResultsParser();

export const useGetVoucherNonces = () => {
    const { network } = useGetNetworkConfig();
    const [ voucherNonces, setVoucherNonces ] = useState<number[]|[]>([]);

    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const contract = createSmartContract({});

    const getVoucherNonces = async() => {
        try {
            const query = contract.createQuery({
                func: new ContractFunction('getVoucherNonces')
            });
            const queryResponse = await proxy.queryContract(query);
            const endpointDefinition = contract.getEndpoint('getVoucherNonces');

            const {firstValue} = resultsParser.parseQueryResponse(
                queryResponse,
                 endpointDefinition
            );
            setVoucherNonces(firstValue?.valueOf().map((nonce:bigint)=> Number(nonce)));
            
        } catch (error) {
            console.error('Unable to call getVoucherNonces', error)
        }
    }

    useEffect(() => {
        getVoucherNonces();
    }, []);

    return voucherNonces;
}