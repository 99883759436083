import React, { useState } from 'react';
import { Platform } from './Platforms';
import PlatformContext from './PlatformContext';

const withPlatformContextProvider = (WrappedComponent: any) => (props: any) => {
  const [platform, setPlatform] = useState<Platform>(Platform.MVX);

  return (
    <PlatformContext.Provider value={[platform, setPlatform]}>
      <WrappedComponent {...props} />
    </PlatformContext.Provider>
  )
}

export default withPlatformContextProvider;
