import { domainStorageAddressEt, DomainStorage } from "config";
import { readContract } from '@wagmi/core';
import domainStorageAbi from './DomainStorage.abi.json';

export const useGetNftMedia = () => {
  const getNftMedia = async (record: string) => {
      try {
          let nftId;
          if (record.endsWith('drill')) {
              // If it's a .drill domain, query the drillAddress
              nftId = await readContract({
                  address: DomainStorage, // Assuming you have a drillAddress defined
                  abi: domainStorageAbi,
                  functionName: 'nftCids',
                  args: [record],
              }) as any;
          } else {
              // Otherwise, query the domainStorageAddressEt
              nftId = await readContract({
                  address: domainStorageAddressEt,
                  abi: domainStorageAbi,
                  functionName: 'nftCids',
                  args: [record],
              }) as any;
          }
          return `https://ipfs.io/ipfs/${nftId}`;
      } catch (error: any) {
          console.error('Unable to retrieve NFT media', error);
      }
  };

  return getNftMedia;
};
