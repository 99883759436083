import { domainStorageAddressEt } from 'config';
import { useContractRead } from 'wagmi';
import domainStorageAbi from 'domain-eth/DomainStorage.abi.json';
import { useEffect, useState } from 'react';
import { Loading } from 'components/Loading';
import { PriceBlock } from 'components/PriceBlock/PriceBlock';
import { notification } from 'antd';

export const MaticNftPriceIdentifier = ({
    setIsRegistrationAvailable,
    setItemPrice,
    address,
    names,
}: {
    setIsRegistrationAvailable: Function;
    setItemPrice: Function;
    address?: `0x${string}`;
    names: string[];
}) => {
    const [isEnoughMoney, setIsEnoughMoney] = useState(false);

    const { data, isError, isLoading }: { data: any; isError: boolean; isLoading: boolean } = useContractRead({
        address: domainStorageAddressEt,
        abi: domainStorageAbi,
        functionName: 'getFreeDomainsCount',
        args: [address],
    });

    useEffect(() => {
        if (typeof data !== 'undefined') {
            setIsEnoughMoney(parseInt(data) >= names.length);
            setItemPrice(1);
        }
    }, [data]);

    useEffect(() => {
        setIsRegistrationAvailable(isEnoughMoney);
    }, [isEnoughMoney]);

    useEffect(() => {
        if (isError) {
            notification.error({
                key: 'eth error',
                message: 'Cannot get price details',
            });
        }
    }, [isError]);

    return isLoading ? (
        <Loading />
    ) : (
        <PriceBlock
            price={'0' as any}
            showWarning={!isEnoughMoney}
            token={`NFT to register domain${names.length > 1 ? 's' : ''} for free`}
            uom={'MATIC'}
            multipleDomains
            warning="This wallet has no Dinky Linker!"
        />
    );
};
