import React, { ReactNode, useState } from 'react';
import { MenuOutlined, CloseOutlined, HomeOutlined, SwapOutlined, DollarOutlined, InfoCircleOutlined} from '@ant-design/icons';
import '../App.css'; // Import your CSS file for styling
import { SearchBox } from './SearchBox navbar/SearchBox';
import { useMediaQuery } from 'react-responsive'; 
import { ConnectModal } from './Connect/ConnectModal/ConnectModal'
import KometWallet from '../pages/Wallet'; 
import { Row } from 'antd/lib';
interface NavbarProps {
  children: ReactNode | ReactNode[];
}

const Navbar: React.FC<NavbarProps> = ({ children }) => {
  const [logo, ...renderChildren] = Array.isArray(children) ? children : [children];
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [activeLink, setActiveLink] = useState<string>('');
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return (
    <nav className="navbar">
      {logo && (
        <a href='/'>
          {logo}
        </a>
      )}
      <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        {isMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
      </div>
      <ul className={`navbar-nav ${isMenuOpen ? 'open' : ''}`}>
        <li className="nav-item">
          <a href="/" className={`nav-link ${activeLink === 'home' ? 'active' : ''}`} onClick={() => { setActiveLink('home'); setIsMenuOpen(false); }}>
          <HomeOutlined style={{ fontSize: '20px' }} />
            <span> Home</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="/swap" className={`nav-link ${activeLink === 'swap' ? 'active' : ''}`} onClick={() => { setActiveLink('swap'); setIsMenuOpen(false); }}>
            <SwapOutlined style={{ fontSize: '20px' }} />
            <span> Swap</span>
          </a>

         </li>
          <li className="nav-item">
          <a href="/Referral" className={`nav-link ${activeLink === 'earn' ? 'active' : ''}`} onClick={() => { setActiveLink('earn'); setIsMenuOpen(false); }}>
            <DollarOutlined style={{ fontSize: '20px' }} />
            <span> Earn</span>
          </a>
      </li> 

          <li className="nav-item">
          <a href="https://xdomain.services/" className={`nav-link ${activeLink === 'earn' ? 'active' : ''}`} onClick={() => { setActiveLink('earn'); setIsMenuOpen(false); }}>
            <InfoCircleOutlined style={{ fontSize: '20px' }} />
            <span> About</span>
          </a>

        </li>
        {isDesktop && (
          <li className="nav-item">
            <SearchBox />
          </li>
        )}
        <li className='nav-item'>
        <button onClick={() => setIsWalletOpen(!isWalletOpen)} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none', marginRight: '10px'}}>
                <span className='wallet'>Komet</span>
                    </button>
                    {isModalOpen && <ConnectModal onClose={() => setIsModalOpen(false)} />}
            {isWalletOpen && (
                <div style={{ position: 'fixed', border: "1px solid rgba(255, 255, 255, 0.3)",borderRadius: "16pX",backdropFilter: "blur(60px)", top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
                    <KometWallet onClose={() => setIsWalletOpen(false)} />
                </div>
            )}
                    </li>
        {/* Render additional children here */}
        {renderChildren.map((child, index) => (
          <li className="nav-item" key={index}>
            {child}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navbar;
