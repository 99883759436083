import React, { useState, useEffect } from 'react';
import { useGetIsLoggedIn } from 'hooks';
import { Button, ConfigProvider, Row, theme } from 'antd';
import Col from 'antd/es/grid/col';
import { Layout,message } from 'antd';
import { Connect } from 'components/Connect/Connect';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/xds_pic.png';
import { CloseOutlined } from '@ant-design/icons';
// import logo2 from '../../assets/xds_2.png';
// import logo3 from '../../assets/twitter.png';
// import logo4 from '../../assets/icons8-discord-50.png';
// import bg from '../../assets/mainBgBlue.png';
import './Home.css';
import { SearchBox } from 'components/SearchBox/SearchBox';
import { NetworkType } from 'components/NetworkType';
import Navbar from 'components/Navbar';
import UpdateBar from 'components/Updates';
import { useMediaQuery } from 'react-responsive';
import NotifyBar from 'components/notification';
import { Space } from 'antd';
import { collection, addDoc, getDoc, getDocs, updateDoc,getFirestore, query, where, setDoc,doc} from "firebase/firestore"; 
import { initializeApp } from "firebase/app";
import 'firebase/database';
import { useAccount } from 'wagmi';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { Firestore } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);


const generateTextsWithLogos = (texts: string[]): { text: string }[] => {
    return texts.map((text) => ({ text }));
};

  

export const Home = () => {
    const [showReferralPopup, setShowReferralPopup] = useState(false);
    const [referral, setReferral] = useState<string>('');
    const isLoggedIn = useGetIsLoggedIn();
    const texts = [".drill",".rat", ".pol", ".egld", ".mvx", ".bear", ".ape", ".erd", ".mex", ".hype", ];
    const textsWithLogos = generateTextsWithLogos(texts);
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const { address: ethAddress } = useAccount();
    const { address } = useGetAccountInfo();
    const getUserIds = async () => {
        try {
            const usersQuerySnapshot = await getDocs(collection(db, 'users'));
            const userIds: string[] = [];
            console.log(userIds);
            
            // Iterate over each document in the query snapshot
            usersQuerySnapshot.forEach((userDoc) => {
                // Get the ID of each user document and add it to the userIds array
                userIds.push(userDoc.id);
            });
    
            return userIds;
        } catch (error) {
            console.error('Error fetching user documents:', error);
            return [];
        }
    };
  
    
    const checkAndSetReferral = async () => {
        try {
            // Get all user IDs
            const userIds = await getUserIds();
            const value = referral;
    
            // Iterate over each user ID
            for (const userId of userIds) {
                // Construct the reference to the user document
                const userDocRef = doc(db, 'users', userId);
    
                // Get the current data of the user document
                const userDocSnapshot = await getDoc(userDocRef);
                const userData = userDocSnapshot.data();
                const username = userData ? userData.username : null;
                if (username === value) {
                    // Extract the current referrals array from the user data or create a new empty array if it doesn't exist
                    const referrals = userData && userData.referrals ? userData.referrals : [];
    
                    // Check if the referrals array already includes the ethAddress or address
                    if (referrals.includes(ethAddress || address)) {
                        // If the address is already present, display an error message
                        message.error('Referral Already set');
                        continue; // Skip to the next user
                    }
    
                    // Add the new address to the referrals array
                    const newReferrals = [...referrals, ethAddress || address];
    
                    // Update the user document with the new referrals array
                    await setDoc(userDocRef, { referrals: newReferrals }, { merge: true });
    
                    message.success('Referral Added Successfully');
                }
            }
        } catch (error) {
            console.error('Error adding address to referrals array:', error);
        }
    };
    
    
    
    
    
    useEffect(() => {
        /*eslint-disable no-restricted-globals */
        // Check if the referral parameter exists in the URL query string
        const params = new URLSearchParams(location.search);
        if (params.has('referral')) {
            // Display the referral popup
            setShowReferralPopup(true);
        }
    }, [location]);

    const handleCloseReferralPopup = () => {
        // Close the referral popup
        setShowReferralPopup(false);
    };
    useEffect(() => {
        // Extract referral value from URL parameter
        const urlParams = new URLSearchParams(window.location.search);
        const referralFromUrl = urlParams.get('referral');

        // Set referral value to state
        if (referralFromUrl) {
            setReferral(referralFromUrl);
        }
    }, []);


    return (
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
            }}
        >
            <Layout className="home-layout" >
                <Row style={{ padding: '20px', color: '#fff' }} justify="space-between">
                    <Navbar>
                        <img className="logo-img" src={logo} alt="logo_img" style={{ width: '50px', height: '50px' }} />
                        <Connect showSelect contentClassName="connect-transparent">
                            <ConfigProvider
                                theme={{
                                    algorithm: theme.darkAlgorithm,
                                }}
                            >
                                <div className="connect-typography">
                                    {isLoggedIn ? <NetworkType /> : null}

                                </div>
                            </ConfigProvider>
                        </Connect>
                    </Navbar>
                </Row>
                <Layout.Content className="home-content">
                    <Row justify="center">
                        <Col xs={24} md={16}>
                            <Row justify="center">
                                <h1 className='header'>Own your digital identity</h1>
                            </Row>
                            <Row justify="center">
                                <p className='details'>Strengthen your online footprint with our specialized Web3 domain services, Interact with the vibrant Web3 ecosystems and acquire a distinctive domain for your community's digital space.</p>
                            </Row>
                            <br />

                            <br />
                            <SearchBox mode="white" />
                            {isDesktop && (
                                <div className='updates-bar'>
                                    <Row justify='center'>
                                        <UpdateBar texts={textsWithLogos} />
                                    </Row>
                                </div>)}
                            <br />
                            <Row style={{ color: '#fff' }} justify="center">
                                <NotifyBar textArray={["Launch custom domain Ending!"]} linkArray="https://example.com" />
                            </Row>
                        </Col>

                    </Row>
                    {isLoggedIn && showReferralPopup && (
                        <div style={{ position: 'relative', width: '400px', height: '370px', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <div className="referral-card" style={{ position: 'fixed', border: "1px solid rgba(255, 255, 255, 0.3)", borderRadius: "16pX", backdropFilter: "blur(60px)", top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999,  width: '400px', height: '370px', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <Button
                                    type="text"
                                    shape="circle"
                                    icon={<CloseOutlined style={{ fontSize: '20px', color: 'white' }} />}
                                    style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'transparent', border: 'none', cursor: 'pointer'  }}
                                    onClick={handleCloseReferralPopup}
                                />
                                
                                
                                <h2 className='popup-heading'>Referral Setup</h2>
                                <p className='heading'>Set Your friend Xdomain name as a referral Code</p>
                                <Space.Compact className='input-popup' style={{fontWeight:"bold", fontSize:"20px"}}>
                                <input type="text" value={referral} onChange={(e) => setReferral(e.target.value)} />
                                </Space.Compact>
                                <Button type="primary" onClick={checkAndSetReferral} className='popup-button'>Set Referral</Button>

                            </div>
                            {/* Background Overlay */}
                            <div className="referral-overlay" onClick={handleCloseReferralPopup}></div>
                        </div>
                    )}
                </Layout.Content>
            </Layout>
        </ConfigProvider>
    );
};
