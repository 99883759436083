import { Button, Col, Divider, Layout, Row, Typography } from 'antd';
import { Connect } from 'components/Connect/Connect';
import { Link, Outlet } from 'react-router-dom';
import './CommonLayout.css';
import logo from '../../assets/xds_pic.png';
import { SearchBox } from 'components/SearchBox/SearchBox';
import { useState } from 'react';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { AddressComponent } from 'components/AddressComponent';
import { NetworkType } from 'components/NetworkType';
import { InfoCircleOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { CartIcon } from 'components/Cart/CartIcon';
import withCartContextProvider from 'providers/cart/withCartProvider';
import { CartModal } from 'components/Cart/CartModal';

const CommonLayoutComponent = () => {
    const isLoggedIn = useGetIsLoggedIn();
    const [isNavVisible, setNavVisibility] = useState(false);

    const toggleNav = () => {
        setNavVisibility(!isNavVisible);
    };

    const [isCartModalOpen, setIsCartModalOpen] = useState(false);

    const showCartModal = () => {
        setIsCartModalOpen(true);
    };

    const closeCartModal = () => {
        setIsCartModalOpen(false);
    };

    return (
        <Layout style={{ minHeight: '200vh' }}>
            <Layout.Header className="layout-header">
                <Link to="/" className="logo">
                    <img src={logo} alt="company logo" style={{ height: '50px', width:'50px' }} />
                </Link>
                <div className="header-content desktop-search">
                    <div className="header-search">
                        <div className="header-search-box">
                            <SearchBox />
                        </div>
                    </div>
                </div>

                {isNavVisible && (
                    <div className="header-content">
                        <Row>
                            <div className="header-connect">
                            <CartIcon showModal={showCartModal} />
                                <Connect>
                                    <>
                                        {isLoggedIn ? <AddressComponent /> : <Typography.Paragraph>Read Only</Typography.Paragraph>}
                                        <Typography.Text strong>
                                            <NetworkType />
                                        </Typography.Text>
                                    </>
                                </Connect>
                            </div>
                            <Col xs={24}>
                                <Button type="link" icon={<InfoCircleOutlined />} style={{ color: '#fff' }} size="large">
                                    <a href="https://xdomain.services/" target="_blank" rel="noreferrer">
                                        About Us
                                    </a>
                                </Button>
                                
                                </Col>
                        </Row>
                    </div>
                )}

                <div className="menu-icon">
                    <div className="menu-icon-content">
                        <CartIcon showModal={showCartModal} />
                        <Button icon={isNavVisible ? <CloseOutlined /> : <MenuOutlined />} onClick={toggleNav} />
                    </div>
                </div>
            </Layout.Header>
            <Layout hasSider>
                <Layout.Sider className="sider" theme="light">
                    <Row gutter={[8, 8]} style={{ paddingTop: '100px' }}>
                        <Col xs={24}>
                            <Connect>
                                {isLoggedIn ? <AddressComponent /> : <Typography.Text>Read Only</Typography.Text>}
                                <Typography.Paragraph>
                                    <NetworkType />
                                </Typography.Paragraph>
                            </Connect>
                        </Col>
                        <Col xs={24}>
                            <Button type="link" icon={<InfoCircleOutlined />} style={{ color: '#fff' }} size="large">
                                <a href="https://xdomain.services/" target="_blank" rel="noreferrer">
                                    About Us
                                </a>
                            </Button>
                        </Col>
                    </Row>
                </Layout.Sider>
                <Layout.Content className="layout-content">
                    <Row className="search-mobile">
                        <Col xs={24} md={0}>
                            <SearchBox />
                        </Col>
                    </Row>
                    <CartModal isModalOpen={isCartModalOpen} closeModal={closeCartModal} />
                    <Outlet />
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export const CommonLayout = withCartContextProvider(CommonLayoutComponent);
