import { createSmartContract } from './smartContract';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { BytesValue, ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';

const resultsParser = new ResultsParser();

export const useIsDomainExist = () => {
    const { network } = useGetNetworkConfig();
    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const contract = createSmartContract({});

    const isDomainExist = async ( record: string ) => {
        try {
            const query = contract.createQuery({
                func: new ContractFunction('isDomainExist'),
                args: [ BytesValue.fromUTF8(record)]
            })
            const queryResponse = await proxy.queryContract(query);

            const endpointDefinition = contract.getEndpoint('isDomainExist');
            const { firstValue } = resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );

            const isExist = Boolean(firstValue?.valueOf());
            return {domain:record, isExist};
        } catch (error:any) {
            console.error('Unable to call isDomainExist', error)
        }
    }

    return isDomainExist;

}