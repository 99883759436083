import { useGetIsLoggedIn } from 'hooks';
import { Typography, Alert, Col, Row, Steps } from 'antd';
import { Connect } from 'components/Connect/Connect';
import { useMemo, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import './DomainRegister.css';
import { PaymentSelect, PAYMENT_METHODS } from './components/PaymentSelect';
import { Register } from './Register';
import { PriceIdentifier } from './components/PriceIdentifier';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { Platform } from 'providers/PlatformContext/Platforms';
import { useDomainExist } from 'components/domainExist/DomainExistContext';

const registrationSteps = [
    {
        subTitle: <Typography.Text strong>Request to register</Typography.Text>,
        description:
            'Your wallet will open and you will be asked to confirm the transactions required for registration. If transactions are not processed for some reason, you will need to start again from step 1.',
    },
    {
        subTitle: <Typography.Text strong>Wait for the completion </Typography.Text>,
        description: 'The waiting period is required to ensure that registration is completed successfully and you own your domain name.',
    },
    {
        subTitle: <Typography.Text strong>Complete the Registration</Typography.Text>,
        description:
            'After completion, you will see a message about successful registration and will have the possibility to check details about your domain name.',
    },
];

export const DomainRegister = () => {
    const { domain } = useDomainExist();

    const domainExist = domain.find(({ isExist }) => isExist);
    const isLoggedIn = useGetIsLoggedIn();
    const isHype = domain.length === 1 &&  domain[0].domain?.includes('.hype');
    const [isRegistrationAvailable, setIsRegistrationAvailable] = useState(false);
    const [itemPrice, setItemPrice] = useState();
    const [platform] = usePlatform();

    const defaultPaymentMethod = useMemo(()=> {
        if (platform === Platform.MATIC) return PAYMENT_METHODS.MATIC_REGULAR;
        if (isHype) return PAYMENT_METHODS.HYPE;
        return PAYMENT_METHODS.VOUCHER;
    }, [platform, isHype]);

    const [paymentMethod, setPaymentMethod] = useState<string>(defaultPaymentMethod);

    const renderContent = () =>
        isLoggedIn ? (
            <>
                <PaymentSelect paymentMethod={paymentMethod} onChange={setPaymentMethod} />
                {itemPrice ? <Register isRegistrationAvailable={isRegistrationAvailable} paymentMethod={paymentMethod} price={itemPrice} /> : null}
                <Col xs={24}>
                    <Row justify="end">
                        <PriceIdentifier
                            paymentMethod={paymentMethod}
                            setIsRegistrationAvailable={setIsRegistrationAvailable}
                            setItemPrice={setItemPrice}
                        />
                    </Row>
                </Col>
            </>
        ) : (
            <Connect contentClassName="connect-content">
                <Alert
                    style={{ margin: '0 8px 8px 0' }}
                    message="No wallet connected. Please connect a wallet to continue."
                    type="warning"
                    showIcon
                />
            </Connect>
        );

    return domainExist ? (
        <Alert message={`${domainExist?.domain} is already registered`} type="info" />
    ) : (
        <Row justify="end" gutter={[8, 8]}>
            <Col xs={24}>
                <Typography className="registration-title">Registering a name requires you to complete 3 steps</Typography>
                <Typography.Paragraph className="description">
                    * Favorite the name for easy access in case you close out of your browser.
                </Typography.Paragraph>
                <Steps items={registrationSteps} />
            </Col>
            {renderContent()}
        </Row>
    );
};
