import {
    AbiRegistry,
    SmartContractAbi,
    SmartContract,
    Address
  } from '@multiversx/sdk-core/out';
  import { contractAddress } from 'config';
import json from 'xdomain-registry.abi.json';
import resolverJson from 'address-resolver.abi.json';
import blackListJson from 'blacklist.abi.json';

export enum ABI_TYPE {
    RESOLVER = 'resolver',
    REGISTRY = 'registry',
    BLACK_LIST='black_list'
}
interface ICreateSmartContract {
    address?: string,
    abiType?:ABI_TYPE
}

const ABI_FILE = {
    [ABI_TYPE.RESOLVER]: resolverJson,
    [ ABI_TYPE.REGISTRY ]: json,
    [ABI_TYPE.BLACK_LIST]:blackListJson
    
}

export const createSmartContract = ({ address = contractAddress, abiType = ABI_TYPE.REGISTRY }: ICreateSmartContract) => {
    const abiJson = ABI_FILE[ abiType ];
    
    const abiRegistry = AbiRegistry.create(abiJson as any);
    const abi = new SmartContractAbi(abiRegistry);

    const smartContract = new SmartContract({
        address: new Address(address),
        abi
    });
    
    return smartContract;
  }
