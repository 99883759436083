import { readContract } from '@wagmi/core';
import { domainStorageAddressEt, DomainStorage} from 'config';
import domainStorageAbi from './DomainStorage.abi.json';

export const useGetResolver = () => {
    const getResolver = async (record: string) => {
        try {
            let resolver;
            if (record.endsWith('.drill')) {
                // If it's a .drill domain, query the drillAddress
                resolver = await readContract({
                    address: DomainStorage, // Assuming you have a drillAddress defined
                    abi: domainStorageAbi,
                    functionName: 'getResolver',
                    args: [record],
                }) as any;
            } else {
                // Otherwise, query the domainStorageAddressEt
                resolver = await readContract({
                    address: domainStorageAddressEt,
                    abi: domainStorageAbi,
                    functionName: 'getResolver',
                    args: [record],
                }) as any;
            }
            return resolver;
        } catch (error: any) {
            console.error('Unable to call getResolver', error);
        }
    };

    return getResolver;
};

