import React, {useState, useEffect} from 'react';
import {NotificationOutlined} from '@ant-design/icons';
import { Button } from 'antd';
import '../App.css';

interface UpdateBarProps {
    textArray: string[];
    linkArray: "https://example.com";
  }

const NotifyBar: React.FC<UpdateBarProps> = ({textArray},linkArray)=>{
    const [currentIndex, setCurrentIndex] = useState(0); 
    useEffect(() => {
        const timer = setTimeout(() => {
            const nextIndex = (currentIndex + 1) % textArray.length;
            setCurrentIndex(nextIndex);
        }, 2000); 

        return () => clearTimeout(timer);
    }, [currentIndex, textArray.length]);
    const handleClick = () => {
        console.log(linkArray); 
        window.location.assign("https://forms.gle/S8JC9eWSrr5jbkaK6");
    };

        return (
            <div className="flip-bar">
            <div className="icon-container">
            <NotificationOutlined />
            </div>
            <div className="text-container">
            {textArray[currentIndex]}
            
            <Button type="primary" onClick={handleClick} className="link1" style={{background:'blue'}}> 
            Fill Form
            </Button>
            </div>
            </div>
            );

}

export default NotifyBar;