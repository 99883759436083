import { useIsDomainExist as useMvx } from 'domain/useIsDomainExist';
import { useIsDomainExist as useEth } from 'domain-eth/useIsDomainExist';
import { Platform } from 'providers/PlatformContext/Platforms';

export const useIsDomainExist = () => {
    const mvx = useMvx();
    const eth = useEth();

    return async (records: string[] = []) => {
        return await Promise.all(records.map(async record => {
            const results = await Promise.all([mvx(record), eth(record)]);

            const existsOnPlatform = [Platform.MVX, Platform.MATIC][results.findIndex(res => res?.isExist)];
    
            return { domain: record, isExist: Boolean(existsOnPlatform), existsOnPlatform };
        }))
    };
};
