import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';
export const contractAddress = 'erd1qqqqqqqqqqqqqpgqc0mllscmxpr0g9n9e7uvcg63snkde6fyf5jspz32d7';
export const resolverContractAddress = 'erd1qqqqqqqqqqqqqpgqguyr3r89kupx702420jt0rpk97ptl4wjf5js8s658x';
export const blackListContactAddress = 'erd1qqqqqqqqqqqqqpgqw75hkawdrmjhun4h9qsnnpczwyjauz5cf5js354kjc';

export const domainStorageAddressEt = '0x94C104e1BaF7e4027f3ee57E3f7A0FE2915aD5Ae';
export const xDomainRegistryAddressEth = '0x4b7eBF290e9023a55c490410466371037576991c';
export const resolverAddressEt = '0x2Fea33730c6E88E7F1eC373C90ECa31Ca948F6cb';

export const dAppName = 'XDomain';

export const walletConnectV2ProjectId = 'dd740a4249c92ae32f1c089269dfe6d8';

export const apiTimeout = 6000;
export const transactionSize = 15;

// MAIN NET
export const NETWORK = EnvironmentsEnum.mainnet;
export const NETWORK_ID = '1';


export const StringLibDrill = '0xC50Dcd95ab685e9d98E8eBb8E6BEF668b38458D0';
export const AddressResolver= "0x81cdB5B848322F03e5767B00df75bE23cF4E8109";
export const Blacklist = "0x44fD1d609C873940F2b271a5e290eBBb395c13C1";
export const XDomainRegistry = '0x0cf16362Fa1af74E9Dc880E6EA0126c86e76B97B';
export const DomainStorage = '0x4143aFd767DD36E3C21fFE08edD37b4C06d7dbeD'