import { DeleteOutlined } from '@ant-design/icons';
import { Card, Modal, Row } from 'antd';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { useCart } from 'providers/cart/useCart';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const CartModal = ({ isModalOpen, closeModal }: { isModalOpen: boolean; closeModal: () => void }) => {
    const navigate = useNavigate();
    const [platform] = usePlatform();
    const { items, removeItem, readonly } = useCart();

    const goToRegister = useCallback(() => {
        closeModal();
        const domainList = items.join(',');
        navigate(`/${platform}/names/${domainList}/register`);
    }, [items, platform, closeModal]);

    return (
        <Modal
            title="Register domains"
            open={isModalOpen}
            onOk={goToRegister}
            onCancel={closeModal}
            okText="Register"
            okButtonProps={{ disabled: !items.length }}
        >
            <div className="cart-modal">
                {!items.length ? <Card size="small">No domains in cart.</Card> : null}
                {items.map(item => {
                    return (
                        <Card key={item} size="small" className="cart-modal-item">
                            <Row justify="space-between" align="middle">
                                <div>{item}</div>
                                {readonly ? null : <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => removeItem(item)} />}
                            </Row>
                        </Card>
                    );
                })}
            </div>
        </Modal>
    );
};
