import { useGetAccount, useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { Alert, notification, Space } from 'antd';
import { Loading } from 'components/Loading';
import { getESDTdetails } from 'domain/esdt/getESDTdetails';
import { getEGLDdetails } from 'domain/egld/getEGLDdetails';
import { useGetEsdtPrice } from 'domain/esdt/useGetEsdtPrice';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PAYMENT_METHODS } from './PaymentSelect';
import { useGetEgldPrice } from 'domain/egld/useGetEgldPrice';
import { useGetVoucherNonces } from 'domain/voucher/usegetVoucherNonces';
import { UseGetVoucherId } from 'domain/voucher/useGetVoucherId';
import { getNft } from 'domain/nft/getNft';
import { getCorrectNonces } from 'domain/shared/getCorrectNonces';
import { getESDTList } from 'domain/esdt/getESDTList';
import { useAccount } from 'wagmi';
import { MaticNftPriceIdentifier } from './MaticNftPriceIdentifier';
import { PriceBlock } from 'components/PriceBlock/PriceBlock';
import { MaticRegularPriceIdentifier } from './MaticRegularPriceIdentifier';
import { CustomPrice } from './CustomPrice';
interface IPriceIdentifier {
    paymentMethod?: string;
    setIsRegistrationAvailable: Function;
    setItemPrice: Function;
}

const DEFAULT_PRICE_DECIMALS = 18;
const checkIsEnoughMoney = (price: number, available: number) => available >= price;

const ESDTPriceIdentifier = ({
    name,
    token,
    setIsRegistrationAvailable,
    setItemPrice,
    address,
    url,
}: {
    name: string;
    token: string;
    setIsRegistrationAvailable: Function;
    setItemPrice: Function;
    address: string;
    url: string;
}) => {
    const { network } = useGetNetworkConfig();
    const getEsdtPrice = useGetEsdtPrice();
    const [price, setPrice] = useState<number>();
    const [isEnoughMoney, setIsEnoughMoney] = useState(false);
    const [esdt, setEsdt] = useState<any>();

    useEffect(() => {
        (async () => {
            try {
                const data = await getESDTList({ url: network.apiAddress, address, token });
                if (data.length) {
                    const [esdt, price] = await Promise.all([getESDTdetails({ token, address, url }), getEsdtPrice(token, name.length)]);
                    setEsdt(esdt);
                    if (typeof price === 'number') {
                        setPrice(price / 10 ** (esdt?.decimals || DEFAULT_PRICE_DECIMALS));
                        setItemPrice(price);
                        setIsEnoughMoney(checkIsEnoughMoney(price, data[0].balance));
                    }
                } else {
                    setEsdt(null);
                    notification.warning({
                        key: 'esdt error',
                        message: `User has not ${token} token.`,
                    });
                    setIsEnoughMoney(false);
                }
            } catch (error) {
                notification.error({
                    key: 'esdt error',
                    message: 'Cannot get esdt details',
                });
            }
        })();
    }, [token]);

    useEffect(() => {
        setIsRegistrationAvailable(isEnoughMoney);
    }, [isEnoughMoney]);

    return (
        <Space>
            {esdt ? (
                <PriceBlock price={price} uom={esdt?.name || ''} showWarning={!isEnoughMoney} token={token} />
            ) : (
                <Alert message={`You do not own any ${token}`} type="warning" showIcon />
            )}
        </Space>
    );
};

const EGLDPriceIdentifier = ({
    name,
    setIsRegistrationAvailable,
    setItemPrice,
    address,
    url,
}: {
    name: string;
    setIsRegistrationAvailable: Function;
    setItemPrice: Function;
    address: string;
    url: string;
}) => {
    const getEgldPrice = useGetEgldPrice();

    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState<number>();
    const [isEnoughMoney, setIsEnoughMoney] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const [egld, price] = await Promise.all([getEGLDdetails({ address, url }), getEgldPrice(name.length)]);
                if (typeof price === 'number') {
                    setPrice(price / 10 ** DEFAULT_PRICE_DECIMALS);
                    setItemPrice(price);
                    setIsEnoughMoney(checkIsEnoughMoney(price, egld.balance));
                }
            } catch (error) {
                notification.error({
                    key: 'egld error',
                    message: 'Cannot get egld details',
                });
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        setIsRegistrationAvailable(isEnoughMoney);
    }, [isEnoughMoney]);

    return loading ? <Loading /> : <PriceBlock price={price} showWarning={!isEnoughMoney} uom="Egld" />;
};

const VoucherPriceIdentifier = ({
    setIsRegistrationAvailable,
    address,
    url,
}: {
    setIsRegistrationAvailable: Function;
    address: string;
    url: string;
}) => {
    const voucherId = UseGetVoucherId();
    const voucherNonce = useGetVoucherNonces();
    const [loading, setLoading] = useState(false);

    const [isVoucherAvailable, setIsVoucherAvailable] = useState(false);

    useEffect(() => {
        if (voucherId && voucherNonce) {
            (async () => {
                setLoading(true);
                try {
                    const nft = await getNft({ voucherId, address, url });
                    const correctNonce = getCorrectNonces(nft, voucherNonce, voucherId);
                    setIsVoucherAvailable(Boolean(correctNonce.length));
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [voucherId, voucherNonce]);

    useEffect(() => {
        setIsRegistrationAvailable(isVoucherAvailable);
    }, [isVoucherAvailable]);

    return loading ? (
        <Loading />
    ) : (
        <Space>
            {isVoucherAvailable ? (
                <Alert message="You have voucher/s available." type="success" showIcon />
            ) : (
                <Alert message="You do not have voucher/s available." type="warning" showIcon />
            )}
        </Space>
    );
};

const HypePriceIdentifier = ({
    setIsRegistrationAvailable,
    address,
    url,
}: {
    setIsRegistrationAvailable: Function;
    address: string;
    url: string;
}) => {
    const [loading, setLoading] = useState(false);
    const [isHypeAvailable, setIsVoucherAvailable] = useState(false);
    const nftId = 'IMPAPE-ff4dec';

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const nft = await getNft({ voucherId: nftId, address, url });
                setIsVoucherAvailable(Boolean(nft.length));
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        setIsRegistrationAvailable(!isHypeAvailable);
    }, [isHypeAvailable]);

    return loading ? (
        <Loading />
    ) : (
        <Space>
            {isHypeAvailable ? (
                <Alert message="You have nft available." type="success" showIcon />
            ) : (
                <Alert message="You do not have nft/s available." type="warning" showIcon />
            )}
        </Space>
    );
};

export const PriceIdentifier = ({ paymentMethod, setIsRegistrationAvailable, setItemPrice }: IPriceIdentifier) => {
    const { name } = useParams();
    if (!name) {
        throw new Error('No domains selected.');
    }
    const domainNames = name.split(',').map(n => n.split('.')[0]);
    const domainName = domainNames[0] || '';
    const [method, token = ''] = paymentMethod?.split('::') || [];
    const { network } = useGetNetworkConfig();
    const { address } = useGetAccount();
    const { address: ethAddress } = useAccount();

    const renderContent = {
        [PAYMENT_METHODS.RARE]: (
            <ESDTPriceIdentifier
                url={network.apiAddress}
                name={domainName}
                token={token}
                setIsRegistrationAvailable={setIsRegistrationAvailable}
                setItemPrice={setItemPrice}
                address={address}
            />
        ),
        [PAYMENT_METHODS.EGLD]: (
            <EGLDPriceIdentifier
                url={network.apiAddress}
                name={domainName}
                setIsRegistrationAvailable={setIsRegistrationAvailable}
                setItemPrice={setItemPrice}
                address={address}
            />
        ),
        [PAYMENT_METHODS.VOUCHER]: (
            <VoucherPriceIdentifier url={network.apiAddress} setIsRegistrationAvailable={setIsRegistrationAvailable} address={address} />
        ),
        [PAYMENT_METHODS.HYPE]: (
            <HypePriceIdentifier setIsRegistrationAvailable={setIsRegistrationAvailable} address={address} url={network.apiAddress} />
        ),
        [PAYMENT_METHODS.MATIC_REGULAR]: (
            <MaticRegularPriceIdentifier
                setIsRegistrationAvailable={setIsRegistrationAvailable}
                setItemPrice={setItemPrice}
                address={ethAddress}
                names={domainNames}
            />
        ),
        [PAYMENT_METHODS.MATIC_CUSTOM]: (
            <CustomPrice
                setIsRegistrationAvailable={setIsRegistrationAvailable}
                setItemPrice={setItemPrice}
                address={ethAddress}
                names={domainNames}
            />
        ),
        [PAYMENT_METHODS.MATIC_NFT]: (
            <MaticNftPriceIdentifier
                setIsRegistrationAvailable={setIsRegistrationAvailable}
                setItemPrice={setItemPrice}
                address={ethAddress}
                names={domainNames}
            />
        )
    };

    return renderContent[method];
};
