import { readContract } from '@wagmi/core';
import { domainStorageAddressEt, DomainStorage} from 'config';
import domainStorageAbi from './DomainStorage.abi.json';

export const useGetDomainOwner = () => {
    const getDomainOwner = async (record: string) => {
        try {
            let result;
            if (record.endsWith('.drill')) {
                // If it's a .drill domain, query the drillAddress
                result = await readContract({
                    address: DomainStorage, // Assuming you have a drillAddress defined
                    abi: domainStorageAbi,
                    functionName: 'getDomainOwner',
                    args: [record],
                });
            } else {
                // Otherwise, query the domainStorageAddressEt
                result = await readContract({
                    address: domainStorageAddressEt,
                    abi: domainStorageAbi,
                    functionName: 'getDomainOwner',
                    args: [record],
                });
            }
            return result as string;
        } catch (error: any) {
            console.error('Unable to call getDomainOwner', error);
        }
    };

    return getDomainOwner;
};

