import axios from "axios";

interface IGetNft {
    voucherId: string,
    address: string,
    url:string
}

export const getNft = async ({  voucherId, address,url }: IGetNft) => {
    try {
        const {data} = await axios.get(`${url}/accounts/${address}/nfts`, {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                search: voucherId
            }
        });
        return data;
    } catch (error:any) {
        throw error.message || 'Unable to call getNft';
    }

}