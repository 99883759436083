import React, { useState, useEffect } from 'react';
import { Button, Typography, ConfigProvider, Layout, message, Spin } from 'antd';
import { ethers } from 'ethers';
import { useGetIsLoggedIn } from 'hooks';
import Navbar from "../components/Navbar";
import { Connect } from 'components/Connect/Connect';
import background from "./../assets/background.gif";
import logo from './../assets/xds_pic.png'
import { NetworkType } from 'components/NetworkType';
import registryAbi from 'domain-eth/xDomainRegistry.abi.json';
import { useAccount } from 'wagmi';


const { Title, Text } = Typography;

const DrillAdmin: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [maticBalance, setMaticBalance] = useState<string | null>(null); // Initialize balance as null
  const [fetchingBalance, setFetchingBalance] = useState<boolean>(false); // State to track if balance is being fetched
  const isLoggedIn = useGetIsLoggedIn(); // Check if the user is logged in
  const contractAddress = '0x0cf16362Fa1af74E9Dc880E6EA0126c86e76B97B'; // Your contract address
  const { address: ethAddress } = useAccount();
  const AdminAddress = '0x0920720f73a2c1bb1243decf30c0dd9bd6218315';

  const fetchBalance = async () => {
    try {
      let provider: ethers.providers.Provider;
  
      if (window.ethereum) {
        // If MetaMask (or similar) is available
        provider = new ethers.providers.Web3Provider(window.ethereum);

      } else {
        // Fallback to Infura provider
        const infuraProjectId = '1034e3166a7842b0bdaf90256d568d20'; // Replace with your Infura project ID
        provider = new ethers.providers.InfuraProvider('homestead', infuraProjectId);
      }
  
      setFetchingBalance(true); // Start fetching balance
  
      // Get balance of contract address
      const balance = await provider.getBalance(contractAddress);
      const formattedBalance = ethers.utils.formatEther(balance);
      setMaticBalance(formattedBalance);
  
      // Display success message
      message.success('Balance successfully updated');
    } catch (error: any) {
      message.error(`Balance fetch error: ${error.message}`);
    } finally {
      setFetchingBalance(false); // Stop fetching balance
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchBalance(); // Fetch balance when user is logged in
    }
  }, [isLoggedIn]);


  const withdraw = async () => {
    try {
      let providers: ethers.providers.Provider;
      if (!window.ethereum) {
        throw new Error('Ethereum provider not found. Make sure MetaMask or similar is installed.');
      

    } else {
      // Fallback to Infura provider
      const infuraProjectId = '1034e3166a7842b0bdaf90256d568d20'; // Replace with your Infura project ID
      providers = new ethers.providers.InfuraProvider('homestead', infuraProjectId);
    }

      if (!isLoggedIn) {
        throw new Error('Please connect your wallet.');
      }

      // Connect to signer
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // Connect to the main contract
      const contract = new ethers.Contract(contractAddress, registryAbi, signer);

      // Manually set gas limit
      const gasLimit = 3000000; // You can adjust this value based on your requirements

      // Call the withdraw function with manually set gas limit
      const transaction = await contract.withdraw({
        gasLimit
      });

      // Wait for the transaction to be mined
      await transaction.wait();


      // Display success message
      message.success('Withdraw successful');
    } catch (error: any) {

      // Display error message
      message.error(`Withdraw error: ${error.message}`);
    }
  };

  return (
    <>
      <div>
        <Layout className='home-layout' style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', color: 'white', marginTop: '10px' }}>
          <Navbar>
            <img className="logo-img" src={logo} alt="logo_img" style={{ width: '50px', height: '50px' }} />
            <Connect showSelect contentClassName="connect-transparent">
              <ConfigProvider>
                <div className="connect-typography">
                  {isLoggedIn ? <NetworkType /> : null}
                </div>
              </ConfigProvider>
            </Connect>
          </Navbar>
          {isLoggedIn ? (
            <>
              <Title level={1} style={{ justifyContent: "center", textAlign: "center", color: 'white', marginTop: '80px', marginLeft: '30px' }}>DrillClaim</Title>
              <Title level={2} style={{ textAlign: "center", color: 'white', marginTop: '80px', marginLeft: '30px' }}>Contract Address</Title>
              <Text style={{ textAlign: "center", color: 'white', marginLeft: '30px' }}>{contractAddress}</Text>
              <Title level={3} style={{ textAlign: "center", color: 'white', marginTop: '30px', marginLeft: '30px' }}>Matic Balance</Title>
                <Text style={{ textAlign: "center", color: 'white', marginLeft: '30px' }}>{maticBalance} MATIC</Text>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button type="primary" onClick={withdraw} style={{ marginTop: '20px', width: '150px', height: '50px', fontWeight: "bold", marginLeft: '30px' }}>Claim</Button>
              </div>
            </>
          ) : (
            <Title level={2} style={{ color: 'white', textAlign: "center", marginTop:'20rem' }}>Please connect your wallet.</Title>
          )}
          {error && <Text type="danger" style={{ color: 'red', marginTop: '10px' }}>{error}</Text>}
        </Layout>
      </div>
    </>
  );
};

export default DrillAdmin;
