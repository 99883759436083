import 'antd/dist/reset.css';
import { ConfigProvider, theme } from 'antd';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { TransactionsToastList, SignTransactionsModals, NotificationModal } from '@multiversx/sdk-dapp/UI';
import React, { useState, useEffect } from 'react';
import { CommonLayout } from 'components/CommonLayout/CommonLayout';
import { Domain } from 'pages/Domain/Domain';
import { DomainDetails } from 'pages/Domain/DomainDetails/DomainDetails';
import { DomainRegister } from 'pages/Domain/DomainRegister/DomainRegister';
import { NotFound } from 'pages/NotFound/NotFound';
import { SearchResult } from 'pages/SearchResult/SearchResult';
import { Home } from './pages/Home/Home';
import './App.css';
import { DomainExistProvider } from 'components/domainExist/DomainExistContext';
import withPlatformContextProvider from 'providers/PlatformContext/withPlatformContextProvider';
import { withMvxDappProvider } from 'providers/mvxDapp/withMvxDappProvider';
import { PlatformGuard } from 'guards/PlatformGuard';
import { withEthWagmiProvider } from 'providers/ethWagmi/withEthWagmiProvider';
import Swap from './pages/swap';
// import PreLoader from 'components/PreLoader';
import Wallet from './pages/Iframe';
import Referral from 'pages/Referral/Referral';
import DrillAdmin from 'pages/DrillClaim';

function App() {
    const  [loading, setLoading] = useState(true);
    useEffect(() => {
        // Simulate loading time
        const timeout = setTimeout(() => {
          setLoading(false);
        }, 2000); // Adjust the duration as needed
    
        return () => clearTimeout(timeout);
      }, []);
    return (
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
            }}
        >
            <Router basename={process.env.PUBLIC_URL}>
                <>
                    <SignTransactionsModals />
                    <TransactionsToastList />
                    <NotificationModal />
                    {/* <PreLoader loading={loading} /> */}
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/swap" element={<Swap/>}/>
                        <Route path="/Referral" element={<Referral/>}/>
                        <Route path="/wallet" element={<Wallet/>}/>
                        <Route path="/Claim" element={<DrillAdmin/>}/>
                        <Route path="/:platform" element={<PlatformGuard />}>
                            <Route element={<CommonLayout />}>
                                <Route path="search/:name" element={<SearchResult />} />
                                <Route element={<DomainExistProvider />}>
                                    <Route path="names/:name" element={<Domain />}>
                                        <Route index element={<DomainRegister />} />
                                        <Route path="register" element={<DomainRegister />} />
                                        <Route path="details" element={<DomainDetails />} />
                                    </Route>
                                    
                                    <Route path="*" element={<NotFound />} />
                                </Route>
                            </Route>
                        </Route>
                    </Routes>
                </>
            </Router>
        </ConfigProvider>
    );
}

const AppWithProviders = withMvxDappProvider(withEthWagmiProvider(withPlatformContextProvider(App)));

export default AppWithProviders;
