// HorizontalScrollText.tsx

import React from 'react';

interface TextWithLogo {
  text: string;
}

interface UpdatesBarProps {
  texts: TextWithLogo[];
}

const UpdateBar: React.FC<UpdatesBarProps> = ({ texts }) => {
  const items = texts.map((item, index) => (
    <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
      {item.text}
    </div>
  ));

  const containerStyles: React.CSSProperties = {
    width: '100%',
    overflow: 'hidden',
    border: '1px solid #000',
    whiteSpace: 'nowrap',
    marginTop: '4px',
    color: 'white',
    fontSize:'20px',
    height:'35px',
    fontWeight:'bold'
    
    
  };

  const innerContainerStyles: React.CSSProperties = {
    animation: 'scrollText 25s linear infinite', // Adjust the animation duration as needed
  };

  const textStyles: React.CSSProperties = {
    display: 'inline-block',
    padding: '1px',
  };

  const embeddedCss = `
    @keyframes scrollText {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(-100%);
      }
    }
  `;

  return (
    <div style={containerStyles}>
      <style>{embeddedCss}</style>
      <div style={innerContainerStyles}>
        {items.map((item, index) => (
          <div key={index} style={textStyles}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpdateBar;