import { useAccount } from 'wagmi'
import { usePlatform } from "providers/PlatformContext/usePlatform"
import { useGetIsLoggedIn as useGetIsLoggedInMvx } from '@multiversx/sdk-dapp/hooks';
import { useMemo } from "react";
import { Platform } from "providers/PlatformContext/Platforms";


export const useGetIsLoggedIn = () => {
  const [platform] = usePlatform();
  const isLoggedInMvx = useGetIsLoggedInMvx();
  const { isConnected } = useAccount();

  const isLoggedIn = useMemo(() => {
    if (platform === Platform.MVX) {
        return isLoggedInMvx;
    }
    if (platform === Platform.MATIC) {
        return isConnected;
    }
}, [platform, isConnected, isLoggedInMvx]);

  return isLoggedIn;
}