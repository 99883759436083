import { Address } from '@multiversx/sdk-core/out';
const BigNumber = require('bignumber.js');


export const hexToNumber = (hex: string): number => {
    return parseInt(hex, 16);
}

export const stringToHex = (str: string) => {
    return Buffer.from(str).toString('hex');
}


export const numberToHex = (num: number) => {
    const bn = new BigNumber(num);
    let result = bn.toString(16);
    if (result.length % 2 !== 0) {
        result = "0" + result;
    }
    while (result.length < 6) {
        result = "0" + result;
    }
    return result;
}

export const bech32ToHex = (value:string) => {
    return Address.fromBech32(value).hex();
}
