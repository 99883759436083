import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { BytesValue, ContractFunction, ResultsParser, Address } from'@multiversx/sdk-core/out';
import { getResolverContract, useGetResolver } from "./useGetResolver";

const resultsParser = new ResultsParser();

export const useGetResolverData = () => {
    const { network } = useGetNetworkConfig();
    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const getResolver = useGetResolver();

    const getResolverData = async ( record: string ) => {
        try {
            const contract = await getResolverContract(record, getResolver);
            const query = contract.createQuery({
                func: new ContractFunction('getWalletAddress'),
                args: [ BytesValue.fromUTF8(record)]
            })
            const queryResponse = await proxy.queryContract(query);

            

            const endpointDefinition = contract.getEndpoint('getWalletAddress');
            const {firstValue}= resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );
            const result = firstValue?.valueOf();
            return new Address(result).bech32();
        } catch (error:any) {
            console.error('Unable to call getWalletAddress', error)
        }
    }

    return getResolverData;
}