import { Loading } from 'components/Loading';
import { usePlatform } from 'providers/PlatformContext/usePlatform';
import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

export const PlatformGuard = () => {
    const { platform } = useParams();
    const [, setPlatform] = usePlatform();
    const [platformSync, setPlatformSync] = useState(false);

    useEffect(() => {
        setPlatform(platform);
        setPlatformSync(true);
    }, [platform]);

    return platformSync ? <Outlet /> : <Loading />;
};
