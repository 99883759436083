import axios from "axios";
import { inRange } from "lodash";

interface IGetNFTMedia {
    nftTokenId: string,
    nftNonce: number,
    url:string
}

export const getNFTMedia = async ({ nftTokenId, nftNonce , url}: IGetNFTMedia) => {
    const preparedNftNonce = inRange(nftNonce, 1, 10) ? `0${nftNonce}` : nftNonce;
    try {
        const {data} = await axios.get(`${url}/nfts/${nftTokenId}-${preparedNftNonce}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return data;
    } catch (error:any) {
        throw error.message || 'Unable to call getNFTMedia';
    }

}