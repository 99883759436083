import { ABI_TYPE, createSmartContract } from './smartContract';
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { BytesValue, ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';
import { blackListContactAddress } from 'config';



const resultsParser = new ResultsParser();

export const useIsDomainInBlacklist = () => {
    const { network } = useGetNetworkConfig();
    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const contract = createSmartContract({address:blackListContactAddress, abiType:ABI_TYPE.BLACK_LIST});

    const isDomaInBlacklist = async ( record: string ) => {
        try {
            const query = contract.createQuery({
                func: new ContractFunction('isDomainBlacklisted'),
                args: [ BytesValue.fromUTF8(record)]
            })
            const queryResponse = await proxy.queryContract(query);

            const endpointDefinition = contract.getEndpoint('isDomainBlacklisted');
            const { firstValue } = resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );

            const isInBlacklist = Boolean(firstValue?.valueOf());
            return isInBlacklist;
        } catch (error:any) {
            console.error('Unable to call isDomainBlacklisted', error)
        }
    }

    return isDomaInBlacklist;

}