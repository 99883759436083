import { Radio, Typography, Row, Divider, Col } from 'antd';
import { RecordCard } from 'components/RecordCard/RecordCard';
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { useDomainExist } from 'components/domainExist/DomainExistContext';
import { Loading } from 'components/Loading';

export const Domain = () => {
    const { domain, loading } = useDomainExist()!;
    const { pathname } = useLocation();
    const { name } = useParams();

    const [activeView, setActiveView] = useState('');

    const selectedNames = name?.split(',');

    useEffect(() => {
        const currentView = pathname.split('/')[4] || 'register';
        setActiveView(currentView);
    }, [pathname]);

    if (loading) {
        return <Loading />;
    }

    return (
        <Row gutter={[8, 8]}>
            <Col xs={24}>
                <RecordCard isRecordExist={!!domain?.some(d => d.isExist)}>
                    <Row justify="space-between">
                        <div>
                            {selectedNames?.map(selectedName => (
                                <div key={selectedName}>
                                    <Typography.Text copyable style={{ fontSize: '20px' }}>
                                        {selectedName}
                                    </Typography.Text>
                                </div>
                            ))}
                        </div>

                        <Radio.Group value={activeView}>
                            <Radio.Button value="register">
                                <Link to="register">Register</Link>
                            </Radio.Button>
                            <Radio.Button value="details">
                                <Link to="details">Details</Link>
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                    <Divider />
                    <Outlet />
                </RecordCard>
            </Col>
        </Row>
    );
};
