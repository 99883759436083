import { Alert, Space, Typography } from 'antd';

export const PriceBlock = ({
    price,
    showWarning,
    uom,
    token,
    multipleDomains,
    warning: customWarning,
}: {
    price?: number;
    showWarning: boolean;
    uom: string;
    token?: string;
    multipleDomains?: boolean;
    warning?: string;
}) => {
    const warning = customWarning || `Not enough ${token || uom}.`;

    if (!price) {
        return <Typography.Paragraph>No data.</Typography.Paragraph>;
    }

    return (
        <>
            <Typography.Paragraph>
                <Space>
                    <Typography.Text>
                        <b>Price:</b>
                        &nbsp;{price}&nbsp;
                        {uom}
                        {multipleDomains ? '' : ' per domain.'}
                    </Typography.Text>
                    {showWarning ? (
                        <Alert message={warning} type="warning" showIcon />
                    ) : (
                        <Alert message={`you have available ${token || uom}.`} type="success" showIcon />
                    )}
                </Space>
            </Typography.Paragraph>
        </>
    );
};
