import React from 'react';
import googleLogo from '../assets/google.png';
import Logo from "../assets/xds_pic.png";
import { CloseOutlined } from '@ant-design/icons';


interface KometWalletProps {
    onClose: () => void;
}

const KometWallet: React.FC<KometWalletProps> = ({ onClose }) => {
    const ID = process.env.REACT_APP_PARTNER_ID || '';
    const KEY = process.env.REACT_APP_PARTNER_KEY || '';

    const handleConnectWithGoogle = () => {
        const redirectUrl = process.env.REACT_APP_REDIRECT_URL || 'https://scaling-telegram-665jjr44x44c4gjq-3000.app.github.dev/Wallet' ;
        window.location.href = `https://sdk.komet.me/google/partner/auth?partnerId=${ID}&partnerKey=${KEY}&redirectUrl=${redirectUrl}`;
    };

    return (
        <div style={{ position: 'relative', width: '370px', height: '450px', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <button onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                <CloseOutlined style={{ fontSize: '20px', color: 'white' }} />
            </button>
            <div style={{ marginBottom: '60px', textAlign: 'center', color: 'white' }}>
                <h1 style={{ marginBottom: '0' }}>
                    <img src={Logo} alt="no connection" style={{ height: "60px", width: '60px' }} /> XDomainServices
                </h1>
            </div>
                    <>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '60px' }}>
                            <p style={{ color: 'white', marginRight: '5px' }}>Login interface for Komet wallet</p>
                        </div>
                        <button onClick={handleConnectWithGoogle} style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', padding: '10px', cursor: 'pointer', color: 'black' }}>
                            <img src={googleLogo} alt="Google" style={{ height: "24px", width: '24px', marginRight: '8px' }} />Connect with Google
                        </button>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '60px' }}>
                            <p style={{ color: 'white', marginRight: '5px' }}>Powered by</p>
                            <p style={{ fontWeight: 'bold', color: '#FF10F0' }}>Komet</p>
                        </div>
                    </>
              
        </div>
    );
};

export default KometWallet;