import { useCallback, useMemo, useState } from 'react';
import { CartContext } from './CartContext';

const withCartContextProvider = (WrappedComponent: any) => (props: any) => {
    const [items, setItems] = useState<string[]>([]);
    const [readonly, setReadonly] = useState(false);

    const addItem = useCallback(
        (item: string) => {
            if (items.includes(item)) {
                return items;
            }
            const updatedItems = items.concat(item);
            setItems(updatedItems);
            return updatedItems;
        },
        [items, setItems]
    );

    const removeItem = useCallback(
        (item: string) => {
            setItems(items.filter(val => val !== item));
        },
        [items, setItems]
    );

    const clear = useCallback(() => {
        setItems([]);
    }, [setItems]);

    const resetItems = useCallback(
        (newItems: string[]) => {
            setItems(newItems);
        },
        [setItems]
    );

    const contextValue = useMemo(
        () => ({ items, addItem, removeItem, clear, setItems: resetItems, readonly, setReadonly }),
        [items, addItem, removeItem, clear, resetItems, readonly, setReadonly]
    );

    return (
        <CartContext.Provider value={contextValue}>
            <WrappedComponent {...props} />
        </CartContext.Provider>
    );
};

export default withCartContextProvider;
