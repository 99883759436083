import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { BytesValue, ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';
import { createSmartContract } from 'domain/smartContract';


const resultsParser = new ResultsParser();

export const UseGetNFTNonce = () => {
    const { network } = useGetNetworkConfig();
    const proxy = new ProxyNetworkProvider(network.apiAddress);
    const contract = createSmartContract({});

    const getNFTNonce = async (domainName:string) => {
        try {
            const query = contract.createQuery({
                func: new ContractFunction('getNonce'),
                args: [ BytesValue.fromUTF8(domainName)]
            });
            const queryResponse = await proxy.queryContract(query);
            const endpointDefinition = contract.getEndpoint('getNonce');

            const {firstValue} = resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );

            const result = Number(firstValue?.valueOf());
            return result;

        } catch (error) {
            console.error('Unable to call getNFTNonce', error);
        }
    }

    
    return getNFTNonce;
}