import axios from "axios";

interface IGetGLDdetails {
    address: string,
    url:string
}

export const getEGLDdetails = async ({  address,url }: IGetGLDdetails) => {
    try {
        const {data} = await axios.get(`${url}/accounts/${address}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return data;
    } catch (error:any) {
        throw error.message || 'Unable to call getEGLDdetails';
    }

}